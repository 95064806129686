import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appSettingDataSelector } from "../../redux/appSetting";
import MainLogo from "../Assets/images/MainLogo.png";
import { ReactComponent as IBMainLogo } from "../Assets/svgs/ib-main-logo.svg";
const logoWidth = "100%";
const logoHeight = "80px";

function MaintenanceLayout() {
  const { appSettingData } = useSelector(appSettingDataSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (process.env.REACT_APP_ENV == "prod") {
      if (
        appSettingData?.items?.features?.website_status[0]?.key_value != "1"
      ) {
        navigate("/");
      }
    }
  }, [appSettingData]);

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        flexDirection: "column",
        background: "#f5faff",
      }}
    >
      <Box
        sx={{
          width: logoWidth,
          height: logoHeight,
          maxWidth: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 2,
        }}
      >
        <IBMainLogo style={{ width: 400, height: 80 }} />
        {/* <img src={MainLogo} style={{width: '100%'}} alt="imagesbazaar" /> */}
      </Box>
      <Typography variant="h2">Website Under Construction</Typography>
      <style>
        {`
                html, body, #root{
                    height: 100%
                }
            `}
      </style>
    </Box>
  );
}

export default MaintenanceLayout;
