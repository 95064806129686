import axios from "axios";
import contributoraxois from "../AxiosInterceptor";
import ApiConfig from "../config/ApiConfig";
import {
  setLoading,
  setLoadingImageInfo,
  setEntireShootData,
  setLoadingSimilarImages,
  setEntireShootDataError,
  setSameModelData,
  setSameModelDataError,
  setImageInformationData,
  setImageInformationDataError,
  clearImageData,
  setModelReleaseData,
  setModelReleaseDataError,
  setLoadingImageKeywords,
  setKeywordsData,
  setKeywordsDataError,
} from "../redux/imageInformation";
import { toast } from "react-toastify";
import { STATUS_200, STATUS_300 } from "../data/constants";
import { errorMessage } from "../lib/helper";

export const GetEntireShootApi = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        dispatch(clearImageData());
        const resp = await axios.post(`${ApiConfig.getentireShoot}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setEntireShootData(resp.data?.data?.shootExist));
          dispatch(setLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setEntireShootDataError({
            isError: true,
            message: errorMessage(e),
          })
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setLoading(false));
      }
    };
  }
};

//fileUploadImage

export const fileUploadImage = async (filePayload, header) => {
  try {
    const resp = await contributoraxois.post(`${ApiConfig.uploadImage}`, filePayload, header);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp.data
    } else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const GetSameModelApi = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        dispatch(clearImageData());
        const resp = await axios.get(
          `${ApiConfig.getSameModelImages}/?imageId=${payload}`
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSameModelData(resp?.data?.data));
          dispatch(setLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSameModelDataError({
            isError: true,
            message: errorMessage(e),
          })
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setLoading(false));
      }
    };
  }
};

export const GetModelReleaseData = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        dispatch(clearImageData());
        const resp = await axios.post(
          `${ApiConfig.getModelReleaseImages}`,
          payload
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setModelReleaseData(resp?.data?.data?.modelreleaseidtotal));
          dispatch(setLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setModelReleaseDataError({
            isError: true,
            message: errorMessage(e),
          })
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setLoading(false));
      }
    };
  }
};

export const GetSimilarImages = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoadingSimilarImages(true));
        dispatch(clearImageData());
        const resp = await axios.post(
          `${ApiConfig.getSimilarImages}/?imageId=${payload}`
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setLoadingSimilarImages(false));
          return resp?.data;
        } else {
          throw resp;
        }
      } catch (e) {
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setLoadingSimilarImages(false));
        return resp?.data;
      }
    };
  }
};

export const GetImageInformation = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        dispatch(clearImageData());
        const resp = await axios.get(
          `${ApiConfig.getImageInformation}/${payload}`
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setImageInformationData(resp?.data?.data));
          dispatch(setLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setImageInformationDataError({
            isError: true,
            message: errorMessage(e),
          })
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setLoading(false));
      }
    };
  }
};

export const GetSingleImageDetails = (payload, typeOfData) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoadingImageInfo(true));
        const resp = await axios.get(
          `${ApiConfig.singleImageData}/${payload}?type=${typeOfData}`
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setLoadingImageInfo(false));
          return resp?.data?.data;
        } else {
          throw resp;
        }
      } catch (e) {
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setLoadingImageInfo(false));
        return null;
      }
    };
  }
};
export const GetOrderPlanData = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoadingImageInfo(true));
        const resp = await axios.post(`${ApiConfig.orderPlanData}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setLoadingImageInfo(false));
          return resp?.data?.data;
        } else {
          throw resp;
        }
      } catch (e) {
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setLoadingImageInfo(false));
        return null;
      }
    };
  }
};
export const GetMagnifiedImage = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(
        `${ApiConfig.resizeImage}/${payload.image_name}?shootid=${payload.shootid}`
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp?.data?.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return null;
    }
  }
};

export const GetImageKeywords = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoadingImageKeywords(true));
        const resp = await axios.get(
          `${ApiConfig.getImageKeywords}/${payload}`
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setKeywordsData(resp?.data?.data));
          dispatch(setLoadingImageKeywords(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setKeywordsDataError({
            isError: true,
            message: errorMessage(e),
          })
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setLoadingImageKeywords(false));
      }
    };
  }
};

export const AddPrivilegeClient = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.privilegeDownloadImage}`,
        payload
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
