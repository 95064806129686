import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  cartData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    totalCount: 0,
  },
  addCartData: {
    error: {
      isError: false,
      message: "",
    },
    isSuccess: false,
  },
  removeCartData: {
    error: {
      isError: false,
      message: "",
    },
  },
};

export const cartDataSlice = createSlice({
  name: "cartData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCartData: (state, action) => {
      state.cartData.items = action.payload;
      state.cartData.totalCount = action.payload?.length;
    },
    setCartDataError: (state, action) => {
      state.cartData.error = action.payload;
      state.cartData.items = [];
      state.cartData.totalCount = 0;
    },
    setSuccessAddCartData: (state, action) => {
      state.addCartData.isSuccess = action.payload;
    },
    setAddCartDataError: (state, action) => {
      state.addCartData.error = action.payload;
      state.addCartData.isSuccess = false;
    },
    setRemoveCartDataError: (state, action) => {
      state.removeCartData.error = action.payload;
    },
    // setOpenWishlistDialog: (state, action) => {
    //   state.addWishlistData.openWishlistDialog = action.payload;
    // },
    // setRemoveImageFromWishlistError: (state, action) => {
    //   state.removeWishlistData.error = action.payload;
    //   state.removeWishlistData.openRemoveWishlistDialog = false;
    // },
    // setOpenRemoveWishlistDialog: (state, action) => {
    //   state.removeWishlistData.openRemoveWishlistDialog = action.payload;
    // },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setCartData,
  setCartDataError,
  setAddCartDataError,
  setRemoveCartDataError,
  setSuccessAddCartData,
} = cartDataSlice.actions;

export default cartDataSlice.reducer;

export const cartDataSelector = (state) => state.cartData;
