import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    UploadImagesData: {
        error: {
            isError: false,
            message: "",
        },
        items: [],
        totalCount: 0,
    },
    removeImageData: {
        error: {
            isError: false,
            message: "",
        },
        openConfirmationDialog: false,
    },
};

export const ImagesDataSlice = createSlice({
    name: "uploadImagesData",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setUploadImagesData: (state, action) => {
            state.UploadImagesData.items = action.payload;
            state.UploadImagesData.totalCount = action.payload?.length;
        },
        setUploadImagesDataError: (state, action) => {
            state.UploadImagesData.error = action.payload;
            state.UploadImagesData.items = [];
            state.UploadImagesData.totalCount = 0;
        },
        setRemoveUploadImageError: (state, action) => {
            state.removeImageData.error = action.payload;
            state.removeImageData.openConfirmationDialog = false;
        },
        setOpenConfirmationDialog: (state, action) => {
            state.removeImageData.openConfirmationDialog = action.payload;
        },
        setUploadUITrigger: (state, action) => {
            state.triggerUploadRerender = action.payload;
        },

    },
});

// Action creators are generated for each case reducer function
export const {
    setLoading,
    setUploadImagesData,
    setUploadImagesDataError,
    setOpenConfirmationDialog,
    setRemoveUploadImageError,
    setUploadUITrigger,

} = ImagesDataSlice.actions;

export default ImagesDataSlice.reducer;

export const UploadImagesDataSelector = (state) => state.UploadImagesData;
