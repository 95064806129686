import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingVideoInformation: false,
  loadingVideoKeywords: false,
  videoInformation: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  entireShootVideoData: {
    error: {
      isError: false,
      message: "",
    },
    loading: false,
    items: [],
  },
  videoKeyword: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
};

export const videoInformationSlice = createSlice({
  name: "videoInformation",
  initialState,
  reducers: {
    setLoadingVideoInfo: (state, action) => {
      state.loadingVideoInformation = action.payload;
    },
    setLoadingVideoKeywords: (state, action) => {
      state.loadingVideoKeywords = action.payload;
    },
    setLoadingEntireShootVideo: (state, action) => {
      state.entireShootVideoData.loading = action.payload;
    },
    setVideoInformationData: (state, action) => {
      state.videoInformation.items = action.payload;
    },
    setVideoInformationDataError: (state, action) => {
      state.videoInformation.error = action.payload;
      state.videoInformation.items = [];
    },
    setEntireShootVideoData: (state, action) => {
      state.entireShootVideoData.items = action.payload;
    },
    setEntireShootVideoDataError: (state, action) => {
      state.entireShootVideoData.error = action.payload;
      state.entireShootVideoData.items = [];
    },
    setVideoKeywordsData: (state, action) => {
      state.videoKeyword.items = action.payload;
    },
    setVideoKeywordsDataError: (state, action) => {
      state.videoKeyword.error = action.payload;
      state.videoKeyword.items = [];
    },
    clearVideoData: (state, action) => {
      state.videoInformation.items = [];
      state.entireShootVideoData.items = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoadingVideoInfo,
  setLoadingVideoKeywords,
  setLoadingEntireShootVideo,
  setEntireShootVideoData,
  setEntireShootVideoDataError,
  setVideoInformationData,
  setVideoInformationDataError,
  setVideoKeywordsData,
  setVideoKeywordsDataError,
  clearVideoData,
} = videoInformationSlice.actions;

export default videoInformationSlice.reducer;

export const videoInformationSelector = (state) => state.videoInformationData;
