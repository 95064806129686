import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  loadingImageInformation: false,
  loadingImageKeywords: false,
  loadingSimilarImages: false,
  entireShootData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  sameModelData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  modelReleaseData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  imageInformationData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  imageKeywords: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
};

export const imageInformationSlice = createSlice({
  name: "imageInformationData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingImageInfo: (state, action) => {
      state.loadingImageInformation = action.payload;
    },
    setLoadingSimilarImages: (state, action) => {
      state.loadingSimilarImages = action.payload;
    },
    setLoadingImageKeywords: (state, action) => {
      state.loadingImageKeywords = action.payload;
    },
    setEntireShootData: (state, action) => {
      state.entireShootData.items = action.payload;
    },
    setEntireShootDataError: (state, action) => {
      state.entireShootData.error = action.payload;
      state.entireShootData.items = [];
    },
    setSameModelData: (state, action) => {
      state.sameModelData.items = action.payload;
    },
    setSameModelDataError: (state, action) => {
      state.sameModelData.error = action.payload;
      state.sameModelData.items = [];
    },
    setModelReleaseData: (state, action) => {
      state.modelReleaseData.items = action.payload;
    },
    setModelReleaseDataError: (state, action) => {
      state.modelReleaseData.error = action.payload;
      state.modelReleaseData.items = [];
    },
    setImageInformationData: (state, action) => {
      state.imageInformationData.items = action.payload;
    },
    setImageInformationDataError: (state, action) => {
      state.imageInformationData.error = action.payload;
      state.imageInformationData.items = [];
    },
    setKeywordsData: (state, action) => {
      state.imageKeywords.items = action.payload;
    },
    setKeywordsDataError: (state, action) => {
      state.imageKeywords.error = action.payload;
      state.imageKeywords.items = [];
    },
    clearImageData: (state, action) => {
      state.entireShootData.items = [];
      state.sameModelData.items = [];
      state.imageInformationData.items = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setLoadingImageInfo,
  setLoadingSimilarImages,
  setEntireShootData,
  setEntireShootDataError,
  setSameModelData,
  setSameModelDataError,
  setImageInformationData,
  setImageInformationDataError,
  setLoadingImageKeywords,
  setKeywordsData,
  setKeywordsDataError,
  setModelReleaseData,
  setModelReleaseDataError,
  clearImageData,
} = imageInformationSlice.actions;

export default imageInformationSlice.reducer;

export const imageInformationSelector = (state) => state.imageInformationData;
