import React, { useEffect, useState } from 'react';
import { DATA_LIMIT } from '../../../data/constants';
import { ContributorHistoryOfSoldImages, GetAllConributorImageData, contributorFindImages } from '../../../services/contributorServices';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_IMAGE_URL } from '../../../config/ApiConfig';
import { GetAllSoldImages } from '../../../services/contributorSoldImages';
import CustomButton from '../../Common/Buttons/CustomButton';
import dayjs from 'dayjs';
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import {
    Box,
    Table,
    TableCell,
    TableHead,
    TableBody,
    TableRow,
    Typography,
    IconButton,
    Tooltip,
} from "@mui/material";
import { SoldImagesDataSelector } from '../../../redux/contributorSoldImages';
import '../ContributorSoldImages/contributor.css';
import { useMemo } from 'react';
import Pagination from '../../Common/pagination/Pagination';
import { fileUploadImage } from '../../../services/ImageInformation';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CustomInput from '../../Common/CustomInput/CustomInput';
import CustomAsyncSelect from '../../Common/CustomInput/CustomAsyncSelect';
import { FolderDataSelector } from '../../../redux/contributorFolderList';


const ContributorBrowseFolder = ({ folderName, setFolderName }) => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const limit = DATA_LIMIT;
    const { FolderData } = useSelector(FolderDataSelector);
    const [loading, setLoader] = useState(false);
    const dispatch = useDispatch();
    const contributorData = JSON.parse(localStorage.getItem("contibuter_data"));

    const handleUpload = async () => {
        setLoader(true);
        try {
            if (selectedImageData) {
                const dataa = JSON.parse(localStorage.getItem("contibuter_data"));
                const formData = new FormData();
                if (dataa)
                    formData.append("contributor_id", dataa.contributor_id);
                formData.append("comment", 'Image uploaded');
                for (const image of selectedImageData) {
                    formData.append("files", image);
                }
                let data = await fileUploadImage(formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                if (data?.status === 200) {
                    toast.success("Image uploaded successfully!");
                    navigate('/contributor/uploadImages')
                    setSelectedImageData([]);
                }
            }
        } catch (e) { console.log(e, 'asdf') }
        setLoader(false)
    };
    const getFolderListing = async (inputValue) => {
        // if (inputValue) {
        if (FolderData) {
            let updatedData = FolderData?.items?.map((item, index) => {
                return {
                    value: item.folder_name,
                    label: item.folder_name,
                    data: item
                }
            })
            return updatedData || []
        }
        return []
        // }
    }

    return (
        <>
            <div className="top-reverse-table">
                <Box
                    component="div"
                    className="account-order-table"
                    sx={{
                        backgroundColor: "helpTopicsColors.backgroundColor",
                    }}
                >
                    <Grid container spacing={2} my={2} >
                        <Grid item xs={2} mx={1}>
                            Folder Name :
                        </Grid>
                        <Grid item xs={4}>
                            <CustomInput
                                variant="outlined"
                                fullWidth
                                name="rank"
                                // disabled
                                // value={bankData?.items?.txt_pan_no}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    classes: {
                                        notchedOutline: 'rounded',
                                    },
                                }}
                                onChange={(e) => setFolderName(e.target.value)}
                                inputProps={{
                                    style: {
                                        padding: "8px 10px",
                                        fontSize: 14
                                    }
                                }}
                            />
                            {/* <CustomAsyncSelect
                                promiseOptions={getFolderListing}
                                // error={error}
                                // helperText={helperText}
                                closeMenuOnSelect={false}
                                controlStyle={{
                                    padding: 5,
                                    height: 56
                                }}
                                cacheOptions={false}
                                // value={userSearchData}
                                handleChange={(e) => {
                                    setFolderName(e.target.value)
                                }}
                                width={'100%'}
                                // ref={selectRef}
                                dropDownZIndex={100000}
                                labelShrink={true}
                                label="Enter Folder Name"
                                isMulti={false}
                            onFocus={() => {
                                setCompanyData(null)
                                setUserData(null)
                                setUserSearchData(null)
                                setUserSearchDataMob(null)
                                setFollowUpData([])
                                setUserDataByCompanyState([])
                                setSelectedAssociation(null)
                                setStateData([])
                                formik.resetForm()
                            }}
                            /> */}
                        </Grid>

                    </Grid>
                    <Box
                        component="div"
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <CustomButton
                            className="commonButtonStyles"
                            sx={{
                                borderRadius: (theme) =>
                                    theme.shape.borderRadiusSecondary(15),
                                // marginLeft: "10px",
                                // marginRight: "10px",
                                margin: '10px'
                            }}
                            onClick={() => navigate('/contributor/folderList')}
                        >
                            Cancel
                        </CustomButton>
                        <CustomButton
                            className="commonButtonStyles"
                            type="submit"
                            sx={{
                                borderRadius: (theme) =>
                                    theme.shape.borderRadiusSecondary(15),
                                margin: '10px'
                            }}
                            onClick={() => {
                                if (folderName == "")
                                    toast.error("Please enter folder name.")
                                else
                                    navigate('/contributor/browseImages?tab=2')
                            }
                            }
                        >
                            Next
                        </CustomButton>
                    </Box>
                </Box>

            </div>
        </>
    );

}

export default ContributorBrowseFolder
