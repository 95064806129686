import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  loadingSingleOrder: false,
  pendingOrdersData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  confirmedOrdersData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  outstandingPaymentData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  ivsOrderData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  singleOrderDetails: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    loading: false,
  },
};

export const ordersDataSlice = createSlice({
  name: "ordersData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingSingleOrder: (state, action) => {
      state.loadingSingleOrder = action.payload;
      state.singleOrderDetails.loading = action.payload;
    },
    setSingleOrderData: (state, action) => {
      state.singleOrderDetails.items = action.payload;
    },
    setSingleOrderDataError: (state, action) => {
      state.singleOrderDetails.error = action.payload;
      state.singleOrderDetails.items = [];
    },
    setPendingOrdersData: (state, action) => {
      state.pendingOrdersData.items = action.payload;
    },
    setPendingOrdersDataError: (state, action) => {
      state.pendingOrdersData.error = action.payload;
      state.pendingOrdersData.items = [];
    },
    setConfirmedOrdersData: (state, action) => {
      state.confirmedOrdersData.items = action.payload;
    },
    setConfirmedOrdersDataError: (state, action) => {
      state.confirmedOrdersData.error = action.payload;
      state.confirmedOrdersData.items = [];
    },
    setOutstandingPaymentsData: (state, action) => {
      state.outstandingPaymentData.items = action.payload;
    },
    setOutstandingPaymentsDataError: (state, action) => {
      state.outstandingPaymentData.error = action.payload;
      state.outstandingPaymentData.items = [];
    },
    setIvsOrderData: (state, action) => {
      state.ivsOrderData.items = action.payload;
    },
    setIvsOrderDataError: (state, action) => {
      state.ivsOrderData.error = action.payload;
      state.ivsOrderData.items = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setLoadingSingleOrder,
  setSingleOrderData,
  setSingleOrderDataError,
  setPendingOrdersData,
  setPendingOrdersDataError,
  setConfirmedOrdersData,
  setConfirmedOrdersDataError,
  setOutstandingPaymentsData,
  setOutstandingPaymentsDataError,
  setIvsOrderData,
  setIvsOrderDataError,
} = ordersDataSlice.actions;

export default ordersDataSlice.reducer;

export const ordersDataSelector = (state) => state.ordersData;
