const staticFooterInfoEmail = "info@imagesbazaar.com";
const staticFooterAllIndiaTollFreeNumber = "1800-11-6869";
const staticFooterWhatsappNumber = "+91 99113-66666";
const staticTaxPercentage = 18;

export const staticDataBase = {
  staticFooterInfoEmail,
  staticFooterAllIndiaTollFreeNumber,
  staticFooterWhatsappNumber,
  staticTaxPercentage,
};
