import React,{useState} from 'react'
import MainLayout from '../layouts/MainLayout'
import ContributorSoldImages from '../ContributorSoldImages/ContributorSoldImages'


const soldImagesPage = () => {
    const [navParams, setNavParams] = useState({
        navTitle: "Sold Images",
        navSubTitle: "Welcome to ImagesBazaar Contributor Panel"
    })
  
    const handleNavParams = (obj)=>{
      setNavParams({
          ...navParams,
          ...obj
      })
    }
  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
        <ContributorSoldImages handleNavParams={handleNavParams}/>
    </MainLayout>
   
  )
}

export default soldImagesPage;