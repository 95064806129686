import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  loadingCreateOrder: false,
  checkoutData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
};

export const checkoutDataSlice = createSlice({
  name: "checkoutData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCreateOrderLoader: (state, action) => {
      state.loadingCreateOrder = action.payload;
    },
    setCheckoutData: (state, action) => {
      state.checkoutData.items = action.payload;
    },
    setCheckoutDataError: (state, action) => {
      state.checkoutData.error = action.payload;
      state.checkoutData.items = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setCreateOrderLoader,
  setCheckoutData,
  setCheckoutDataError,
} = checkoutDataSlice.actions;

export default checkoutDataSlice.reducer;

export const checkoutDataSelector = (state) => state.checkoutData;
