import Route from "./route";
import { BrowserRouter as Router } from "react-router-dom";
import "../src/Components/Assets/css/filterdialog.css";
import "../src/Components/Assets/css/aboutus.css";
import "../src/Components/Assets/css/contributorpage.css";
import "../src/Components/Assets/css/homepage.css";
import "../src/Components/Assets/css/imageresearch.css";
import "../src/Components/Assets/css/layout.css";
import "../src/Components/Assets/css/testimonial.css";
import "../src/Components/Common/SearchComponent/style.css";
import "../src/Components/Assets/css/login.css";
import "../src/Components/Assets/css/moreinfo.css";
import "../src/Components/Assets/css/addtocart.css";
import "../src/Components/Assets/css/video.css";
import "../src/Components/Assets/css/changepassword.css";
import "../src/Components/Assets/css/categoryimagelist.css";
import "../src/Components/Assets/css/maincommon.css";
import "../src/Components/Assets/css/technical.css";
import "../src/Components/Assets/css/account-page.css";
import "../src/Components/Assets/css/forgotPass.css";
import "../src/Components/Assets/css/wishlistdialog.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { theme } from "./config/mui-theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "./AxiosInterceptor";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Provider store={store}>
            <Router>
              <Route />
            </Router>
          </Provider>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
