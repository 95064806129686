import axios from "axios";
import { toast } from "react-toastify";

export const configureUrl = (url) => {
  let authUrl = url;
  if (url && url[url.length - 1] === "/") {
    authUrl = url.substring(0, url.length - 1);
  }
  return authUrl;
};

// TO REDUCE TIME OFFSET FOR TIME DIFF.



function adjustForTimezone(date) {
  var timeOffsetInMS = date.getTimezoneOffset() * 60000;
  date.setTime(date.getTime() + timeOffsetInMS);
  return date
}

export function formatDate(d, format = 'dd/mm/yy', adjustOffset = false) {
  if (!d) {
    return ''
  }
  let monthsArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  let date = new Date(d);
  if (adjustOffset) {
    date = adjustForTimezone(new Date(d))
  }
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  var h = date.getHours();
  var hUTC = date.getUTCHours();
  var m = date.getMinutes();
  var timeFormat = "AM";
  var s = date.getSeconds();
  var ms = date.getMilliseconds();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }


  if (h <= 11 && m < 60) {
    timeFormat = "AM";
  } else if (h >= 12) {
    timeFormat = "PM";
  }

  if (format?.includes('12')) {
    h = h % 12;
    h = h ? h : 12; // the hour '0' should be '12'
    m = m;
  }
  if (h < 10) {
    h = "0" + h;
  }
  if (m < 10) {
    m = "0" + m;
  }
  if (s < 10) {
    s = "0" + s;
  }
  if (ms < 10) {
    ms = "0" + ms;
  }

  let formatNeed = format?.toLowerCase();

  if (formatNeed == "dd-mm-yy") {
    return (d = dd + "-" + mm + "-" + yyyy.toString()?.slice(2, 4));
  } else if (formatNeed == "dd/mm/yy") {
    return (d = dd + "/" + mm + "/" + yyyy.toString()?.slice(2, 4));
  } else if (formatNeed == "dd-mm-yyyy") {
    return (d = dd + "-" + mm + "-" + yyyy);
  } else if (formatNeed == "dd/mm/yyyy") {
    return (d = dd + "/" + mm + "/" + yyyy);
  } else if (formatNeed == "yyyy-mm-dd hh:mm:ss.ms") {
    return (d =
      yyyy + "-" + mm + "-" + dd + " " + h + ":" + m + ":" + s + "." + ms);
  } else if (formatNeed == "dd-mm-yyyy hh:mm:ss.ms") {
    return (d =
      dd + "-" + mm + "-" + yyyy + " " + h + ":" + m + ":" + s + "." + ms);
  } else if (formatNeed == "dd-mm-yyyy hh:mm") {
    return (d = dd + "-" + mm + "-" + yyyy + " " + h + ":" + m);
  } else if (formatNeed == "dd-mm-yyyy hh:mm 12h") {
    return (d =
      dd + "-" + mm + "-" + yyyy + " " + h + ":" + m + " " + timeFormat);
  } else if (formatNeed == "yyyy-mm-dd") {
    return (d = yyyy + "-" + mm + "-" + dd);
  } else if (formatNeed == "hh:mm 12h") {
    return (d = h + ":" + m + " " + timeFormat);
  } else if (formatNeed == "iso") {
    return date.toISOString();
  } else if (formatNeed == "utc") {
    return date.toUTCString();
  }
}

export const getDataFromAppSetting = (arr, key, valueKey) => {
  if (arr?.length > 0) {
    const data = arr.filter((data) => data[key] === valueKey);
    return data;
  } else {
    return "N/A";
  }
};
export const getImageName = (data) => {
  const file = data;
  const reader = file.split("/");
  return reader[4];
};
export const constructUrl = (url) => {
  let contain = '';
  let index = url.indexOf('https://');
  if (index >= 0)
    contain = url.replace('https://', '');
  else contain = url;
  return contain;
}
export const cleanPhoneNumber = (value) => {
  try {
    var number = value;
    number = number.replace(/[^+\d]+/g, "");
    return number;
  } catch (e) {
    return "";
  }
};

export function validDate(date, compareDate = null) {
  // const currentDate = new Date();
  // const incomingDate = new Date(date)
  // let crr = formatDate(currentDate);
  // let incr = formatDate(incomingDate);
  // if(crr == incr) {
  //   return false
  // }else{
  //   let status = currentDate > incomingDate
  //   return status;
  // }
  let current_date = new Date();
  if (compareDate) {
    current_date = new Date(compareDate)
  }
  let incoming_date = new Date(date);
  if (current_date.getTime() < incoming_date.getTime()) {
    return true
  } else {
    return false
  }
}


export const errorMessage = (response) => {
  try {
    return (
      response?.response?.data?.message ||
      response?.data?.response?.message ||
      response?.data?.message ||
      response?.message ||
      "Something went wrong"
    );
  } catch (e) {
    return e?.message || "Something went wrong";
  }
};

export const deleteApiPayload = (value) => {
  try {
    return {
      data: value,
    };
  } catch (e) {
    return {
      data: {},
    };
  }
};


export function capitalizeFirstLetter(string) {
  let stringMade='';
  if(string?.length>1){
  stringMade=string.charAt(0)?.toUpperCase() + string.slice(1)?.toLowerCase();
  }
  else if(string?.length==1){
    stringMade=string.charAt(0)
  }
  return stringMade;
}

export function currencyConversion(number) {
  // let NumberTypeCheck=typeof number=='string'?Number(number):number;
  let x = Math.round(number*100)/100;
  x = x.toString();
  var afterPoint = '';
  if (x.indexOf('.') > 0)
    afterPoint = x.substring(x.indexOf('.'), x.length);
  x = Math.floor(x);
  x = x.toString();
  var lastThree = x.substring(x.length - 3);
  var otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers != '')
    lastThree = ',' + lastThree;
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
  return res

}

export async function fetchFile(url, header = {}) {
  return new Promise(async (resolve, reject) => {
    try {
      // const res = await fetch(url);
      // const file = await res.blob();
      // let tempUrl = URL.createObjectURL(file);
      const aTag = document.createElement("a");
      aTag.href = url;
      // aTag.target = '_blank';
      aTag.id = 'downloadId';
      // aTag.download = true;
      // aTag.download = url.replace(/^.*[\\\/]/, "");
      document.body.appendChild(aTag);
      aTag.click();
      // URL.revokeObjectURL(tempUrl);
      aTag.remove();
      resolve();
    } catch {
      toast.error("Failed to download file.");
      reject();
    }
  });
}

export const getSelectFieldSelected = (payload, state, prefix) => {
  let entries = Object.entries(payload);
  let filteredData = state?.items?.filter((val) => {
    if (val[prefix] == entries[0][1]) {
      return val;
    }
  });
  return {
    fieldName: entries[0][0],
    data: filteredData[0],
  };
};
export const getSelectImageName = (state, prefix) => {
  const key = prefix;
  const unique = [...new Map(state.map(item => [item[key], item])).values()]
  return unique;
};
export const getSelectFieldSelectedValue = (data, state, prefix) => {
  let filteredData = state?.items?.filter((val) => {
    if (val[prefix] == data) {
      return val;
    }
  });
  return filteredData[0];
};

export const getFilterdData = (state, key, machingKeyValue) => {
  try {
    let filteredData = state?.filter((item) => {
      return item[key] == machingKeyValue;
    });
    return filteredData;
  } catch (e) {
    return [];
  }
};

export function isAlphaNumeric(str) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return false;
    }
  }
  return true;
}

export function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export const toTitleCase = (str) =>
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1?.toUpperCase() + m2?.toLowerCase()
  );

export const crypt = (salt = "crypt", text) => {
  const textToChars = (text) => text?.split("")?.map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    ?.split("")
    ?.map(textToChars)
    ?.map(applySaltToChar)
    ?.map(byteHex)
    .join("");
};

export const decrypt = (salt = "crypt", encoded) => {
  const textToChars = (text) => text?.split("")?.map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    ?.match(/.{1,2}/g)
    ?.map((hex) => parseInt(hex, 16))
    ?.map(applySaltToChar)
    ?.map((charCode) => String.fromCharCode(charCode))
    .join("");
};

export const checkPaymentOnline = (data) => {
  let isOnline = false;
  if (data?.payment_type) {
    if (!(data?.payment_type?.toLowerCase()?.includes('bank transfer')
      || data?.payment_type?.toLowerCase()?.includes('cheque')
      // || data?.payment_type?.toLowerCase()?.includes('card')
      || data?.payment_type?.toLowerCase()?.includes('demand draft')
      || data?.payment_type?.toLowerCase()?.includes('draft')
      || data?.payment_type?.toLowerCase()?.includes('demand')
      || data?.payment_type?.toLowerCase()?.includes('neft')
      || data?.payment_type?.toLowerCase()?.includes('rtgs')
      || data?.payment_type?.toLowerCase()?.includes('wt'))) {
      isOnline = true
    } else {
      isOnline = false
    }
  }

  return isOnline
}

export const checkForEnablePack = (packType = "", subscriptions = []) => {
  let packAvailable = subscriptions?.webSizePack?.length || subscriptions?.largeSizePack?.length || subscriptions?.smallSizePack?.length
  if (packType?.toLowerCase()?.includes("web")) {
    const webSizePack = subscriptions?.webSizePack
    return {
      result: "Web Size Pack",
      status: webSizePack?.length > 0 ? true : false,
      packAvailable: !!packAvailable
    }
  }
  if (packType?.toLowerCase()?.includes("large")) {
    const largeSizePack = subscriptions?.largeSizePack
    return {
      result: "Large Size Pack",
      status: largeSizePack?.length > 0 ? true : false,
      packAvailable: !!packAvailable
    }
  }
  if (packType?.toLowerCase()?.includes("small")) {
    const smallSizePack = subscriptions?.smallSizePack
    return {
      result: "Small Size Pack",
      status: smallSizePack?.length > 0 ? true : false,
      packAvailable: !!packAvailable
    }
  }
  return {
    result: "",
    status: false,
    packAvailable: !!packAvailable
  }
}