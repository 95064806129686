import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingBank: false,
  bankData: {
    error: {
      isError: false,
      message: "",
    },
    items: {},
  },
 
};

export const bankDataSlice = createSlice({
  name: "bankData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loadingBank = action.payload;
    },
    setBankData: (state, action) => {
      state.bankData.items = action.payload;
    },
    setBankDataError: (state, action) => {
      state.bankData.error = action.payload;
      state.bankData.items = {};
    },
 
 
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setBankData,
  setBankDataError,
 
} = bankDataSlice.actions;

export default bankDataSlice.reducer;

export const bankDataSelector = (state) => state.bankData;

// Action creators are generated for each case reducer function

