import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingContributer: false,
  contributer: {
    error: {
      isError: false,
      message: "",
    },
    contribterDetails: null,
  },
  triggerUiRerender: false,
};

export const loginContributerSlice = createSlice({
  name: "loginContributerData",
  initialState,
  reducers: {
    setloadingContributer: (state, action) => {
      state.loading = action.payload;
    },
    setContributerDetails: (state, action) => {
      state.contributer.contribterDetails = action.payload;
      state.contributer.error = {
        isError: false,
        message: "",
      };
    },
    setContributerDetailsError: (state, action) => {
      state.user.userDetails = null;
      state.user.error = action.payload;
    },
    setTriggerUiRender: (state, action) => {
      state.triggerUiRerender = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setContributerDetails,
  setContributerDetailsError,
  setTriggerUiRender,
  setloadingContributer
} = loginContributerSlice.actions;

export default loginContributerSlice.reducer;

export const loginContributorItemsSelector = (state) => state.loginContributerData;
