import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingWishlist: false,
  wishlistData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    totalCount: 0,
  },
  addWishlistData: {
    error: {
      isError: false,
      message: "",
    },
    openWishlistDialog: false,
  },
  removeWishlistData: {
    error: {
      isError: false,
      message: "",
    },
    openRemoveWishlistDialog: false,
  },
  triggerWishlistRerender: false,
};

export const wishlistDataSlice = createSlice({
  name: "wishlistData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loadingWishlist = action.payload;
    },
    setWishlistData: (state, action) => {
      state.wishlistData.items = action.payload;
      state.wishlistData.totalCount = action.payload?.length;
    },
    setWishlistDataError: (state, action) => {
      state.wishlistData.error = action.payload;
      state.wishlistData.items = [];
      state.wishlistData.totalCount = 0;
    },
    setAddImageToWishlistError: (state, action) => {
      state.addWishlistData.error = action.payload;
      state.addWishlistData.openWishlistDialog = false;
    },
    setOpenWishlistDialog: (state, action) => {
      state.addWishlistData.openWishlistDialog = action.payload;
    },
    setRemoveImageFromWishlistError: (state, action) => {
      state.removeWishlistData.error = action.payload;
      state.removeWishlistData.openRemoveWishlistDialog = false;
    },
    setOpenRemoveWishlistDialog: (state, action) => {
      state.removeWishlistData.openRemoveWishlistDialog = action.payload;
    },
    setWishlistUITrigger: (state, action) => {
      state.triggerWishlistRerender = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setWishlistData,
  setWishlistDataError,
  setAddImageToWishlistError,
  setOpenWishlistDialog,
  setRemoveImageFromWishlistError,
  setOpenRemoveWishlistDialog,
  setWishlistUITrigger,
} = wishlistDataSlice.actions;

export default wishlistDataSlice.reducer;

export const wishlistDataSelector = (state) => state.wishlistData;
