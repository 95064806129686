import axios from "axios";
import ApiConfig from "../config/ApiConfig";
import {
  setLoading,
  setProposalsData,
  setProposalsDataError,
  setSingleProposalsData,
  setSingleProposalsDataLoading,
  setSingleProposalsDataError,
} from "../redux/proposals";
import { toast } from "react-toastify";
import { errorMessage } from "../lib/helper";
import { STATUS_200, STATUS_300 } from "../data/constants";

export const GetAllProposals = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const resp = await axios.post(`${ApiConfig.getAllProposals}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setProposalsData(resp?.data?.data));
          dispatch(setLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setProposalsDataError({
            isError: true,
            message: errorMessage(e),
          })
        );
        toast.error(errorMessage(e), {
          autoClose: 2000,
        });
        dispatch(setLoading(false));
      }
    };
  }
};

export const GetSingleProposalData = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleProposalsDataLoading(true));
        const resp = await axios.post(`${ApiConfig.getAllProposals}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSingleProposalsData(resp?.data?.data));
          dispatch(setSingleProposalsDataLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleProposalsDataError({
            isError: true,
            message: errorMessage(e),
          })
        );
        toast.error(errorMessage(e), {
          autoClose: 2000,
        });
        dispatch(setSingleProposalsDataLoading(false));
      }
    };
  }
};


export const proposalDetailApi = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(`${ApiConfig.proposalDetails}?proposal_id=${payload?.proposal_id}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
