import axios from "axios";
import ApiConfig from "../config/ApiConfig";
import { STATUS_200, STATUS_300 } from "../data/constants";
import { deleteApiPayload, errorMessage } from "../lib/helper";
import { toast } from "react-toastify";
import contributoraxois from "../AxiosInterceptor";
import { setUploadImagesData, setUploadImagesDataError, setLoading, setRemoveUploadImageError, setOpenConfirmationDialog, setUploadUITrigger } from "../redux/contributorUploadImages";
import { setFolderName, setLoadingFolderName } from "../redux/contributorFolderList";

export const GetContributorUploadImages = (payload) => {
    if (payload !== undefined) {
        return async (dispatch) => {
            try {
                dispatch(setLoading(true));
                const resp = await contributoraxois.post(`${ApiConfig.getContributorUploadImages}`, payload);
                if (
                    resp?.data?.status >= STATUS_200 &&
                    resp?.data?.status < STATUS_300
                ) {
                    dispatch(setUploadImagesData(resp?.data?.data));
                    dispatch(setLoading(false));
                } else {
                    throw resp;
                }
            } catch (e) {
                dispatch(
                    setUploadImagesDataError({
                        isError: true,
                        message: errorMessage(e),
                    })
                );
                toast.error(errorMessage(e), {
                    autoClose: 2000,
                });
                dispatch(setLoading(false));
            }
        };
    }
};

export const GetFolderNames = (payload) => {
    if (payload !== undefined) {
        return async (dispatch) => {
            try {
                dispatch(setLoadingFolderName(true));
                const resp = await contributoraxois.post(`${ApiConfig.getFolderNames}`, payload);
                if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
                    const folderName = resp?.data?.data;
                    //   localStorage.setItem("user", JSON.stringify(userData));
                    dispatch(setFolderName(resp?.data?.data));
                    dispatch(setLoadingFolderName(false));
                } else {
                    throw resp;
                }
            } catch (e) {
                toast.error(errorMessage(e), {
                    autoClose: 2500,
                });
            }
        }
    };
};
export const RemoveContributorImage = (payload) => {
    if (payload !== undefined) {
        return async (dispatch) => {
            try {
                const resp = await contributoraxois.delete(
                    `${ApiConfig.deleteUploadedImage}`,
                    deleteApiPayload(payload)
                );
                if (
                    resp?.data?.status >= STATUS_200 &&
                    resp?.data?.status < STATUS_300
                ) {
                    toast.success(resp?.data?.message, {
                        autoClose: 2500,
                    });
                    setOpenConfirmationDialog(true);
                } else {
                    throw resp;
                }
            } catch (e) {
                toast.error(errorMessage(e), {
                    autoClose: 2500,
                });
            }
        };
    }
};