import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  SoldImagesHistory: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    totalCount: 0,
  },
};

export const ImagesSoldHistoryDataSlice = createSlice({
  name: "soldImagesHistory",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSoldImagesHistoryData: (state, action) => {
      state.SoldImagesHistory.items = action.payload;
      state.SoldImagesHistory.totalCount = action.payload?.length;
    },
    setSoldImagesHistoryDataError: (state, action) => {
      state.SoldImagesHistory.error = action.payload;
      state.SoldImagesHistory.items = [];
      state.SoldImagesHistory.totalCount = 0;
    },
  
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setSoldImagesHistoryData,
  setSoldImagesHistoryDataError,

} = ImagesSoldHistoryDataSlice.actions;

export default ImagesSoldHistoryDataSlice.reducer;

export const SoldImagesHistoryDataSelector = (state) => state.SoldImagesHistory;
