import ApiConfig from "./config/ApiConfig";
import axios from "axios";
import { configureUrl } from "./lib/helper";
import { toast } from 'react-toastify';

axios.interceptors.request.use(
    async (config) => {
      let request = config;
      let token = ApiConfig.token;
      if (!token) {
        token = localStorage.getItem("token");
      }
      request.headers = token
        ? {
            Authorization: `${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          }
        : {
            "Content-Type": "application/json",
            Accept: "application/json",
          };
      request.url = configureUrl(config.url);
      return request;
    },
    (error) => {
      throw error.response;
    }
  );
  
  axios.interceptors.response.use(
    (response) => {
      checkTokenIsValid(response);
      return response;
    },
    (error) => errorHandler(error),
  );
  
  const checkTokenIsValid = (response) => {
    if (response?.data?.status == 401) {
      toast.error('Your token is expire please login again.')    
      window.location = '/';
      try {
        localStorage.clear();
      } catch (error) {  }
    }
  };
  
  const errorHandler = async (error) => {
    console.log('errorHandler', error.response);
    throw error.response;
  };

  const contributoraxois = axios.create({
  }); 

  export default contributoraxois;

  contributoraxois.interceptors.request.use(
    async (config) => {
      let request = config;
      let token = ApiConfig.contibuter_token;
      if (!token) {
        token = localStorage.getItem("contibuter_token");
      }
      request.headers = token
        ? {
            Authorization: `${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          }
        : {
            "Content-Type": "application/json",
            Accept: "application/json",
          };
      request.url = configureUrl(config.url);
      return request;
    },
    (error) => {
      throw error.response;
    }
  );
  
  contributoraxois.interceptors.response.use(
    (response) => {
      checkContributerTokenIsValid (response);
      return response;
    },
    (error) => errorHandlerContributor(error),
  );
  
  const checkContributerTokenIsValid = (response) => {
    if (response?.data?.status == 401) {
      toast.error('Your token is expire please login again.')    
      window.location = '/';
      try {
        localStorage.clear();
      } catch (error) {  }
    }
  };
  
  const errorHandlerContributor= async (error) => {
    console.log('errorHandler', error.response);
    throw error.response;
  };