import axios from "axios";
import ApiConfig from "../config/ApiConfig";
import {
  setLoading,
  setAppSettingData,
  setAppSettingDataError,
} from "../redux/appSetting";
import { toast } from "react-toastify";
import { STATUS_200, STATUS_300 } from "../data/constants";
import { errorMessage } from "../lib/helper";
import { setPrivilegeUserFlag, setTriggerUiRender, setUserDetails } from "../redux/login";

export const GetAppSettingData = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp = await axios.get(`${ApiConfig.appSetting}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setAppSettingData(resp?.data?.data));
        dispatch(setLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setAppSettingDataError({
          isError: true,
          message: errorMessage(e),
        })
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setLoading(false));
    }
  };
};
export const GetUserDetails = () => {
  return async (dispatch) => {
    try {
      const resp = await axios.get(`${ApiConfig.getUserDetails}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        const userData = resp?.data?.data;
        localStorage.setItem("user", JSON.stringify(userData));
        dispatch(setUserDetails(resp?.data?.data));
        dispatch(setTriggerUiRender(true));
        if (userData && userData?.privellage_status === "1") {
          dispatch(setPrivilegeUserFlag(true));
        } else {
          dispatch(setPrivilegeUserFlag(false));
        }
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  };
};

export const GetCMSDetails = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp = await axios.get(`${ApiConfig.getCMSdata}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setCMSData(resp?.data?.data));
        dispatch(setLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setAppSettingDataError({
          isError: true,
          message: errorMessage(e),
        })
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setLoading(false));
    }
  };
};

export const getCurrencyDetail = async (amt) => {
  try {
    const resp = await axios.get(`${ApiConfig.exchangeCurrency}INR/USD/${amt}`);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false;
  }
};
export const contactUsApi = async (payload, setLoading) => {
  try {
    const resp = await axios.post(`${ApiConfig.contactUs}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      toast.success(resp.data.message, {
        autoClose: 2500,
      });
      setLoading(false);
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    setLoading(false);
    return false;
  }
};

export const BackgroundImageNoRedux = async () => {
  
  try{
    const  resp = await axios.get(`${ApiConfig.backgroundImage}`);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp.data.data
    } else {
      throw resp
    }
  }catch(e){
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }

};


