import React from "react";
import FooterLogo from "../Assets/images/footer-logo.png";
import { ReactComponent as FaebookIcon } from "../Assets/svgs/facebook-icon.svg";
import { ReactComponent as LinkedInIcon } from "../Assets/svgs/linkedinicon.svg";
import { ReactComponent as TwitterIcon } from "../Assets/svgs/twitter-icon.svg";
import { ReactComponent as WhatsappIcon } from "../Assets/svgs/whatsapp-icon.svg";
import { ReactComponent as PhoneIcon } from "../Assets/svgs/phone-icon-footer.svg";
import { ReactComponent as MailIcon } from "../Assets/svgs/mail-icon-login.svg";
import { ReactComponent as PinterestIcon } from "../Assets/svgs/pinterest-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import PaymentFooter from "../Assets/images/payment-footer.png";
import { Typography } from "@mui/material";
import ApiConfig from "../../config/ApiConfig";
import { staticDataBase } from "../../data/staticData";
import { useEffect } from "react";
import { useState } from "react";
import { CMSDataSelector } from "../../redux/cmsData";
import { useSelector } from "react-redux";

const Footer = ({cmsFooterPage}) => {
  const navigate = useNavigate();
  const [footerData,setFooterData]=useState(null);
  const { FooterPage } = useSelector(CMSDataSelector);
  // console.log(cmsFooterPage,"18");

  // useEffect(()=>{
  //   setFooterData(cmsFooterPage);
  // },[cmsFooterPage])


  useEffect(() => {
    let cmsFooter= FooterPage ? FooterPage?.items?.length > 0 && JSON?.parse(FooterPage?.items[0]?.key_value) : {};
    setFooterData(cmsFooter);
  }, [FooterPage])

  return (
    <>
      <div className="mainFooter">
        <div className="footer-section">
          <div className="footerMainContent">
            {/* <img
              onClick={() => {
                navigate("/");
              }}
              className="main-footer-logo"
              src={FooterLogo}
              alt="FooterLogo"
            /> */}
            <div className="footer-categories">
              <div className="info-section-footer">
                <div className="info-name">
                  <a
                    className="mail-link-footer"
                    href={`mailto:${staticDataBase?.staticFooterInfoEmail}`}
                  >
                    
                    <MailIcon className="footer-contact-icons" />
                  </a>
                  <div className="left-margin-footer">
                    <Typography
                      component="a"
                      variant="mainsectiontitle"
                      className="footer-links"
                      href={`mailto:${staticDataBase?.staticFooterInfoEmail}`}
                    >
                      {footerData?.footerSection?.mail}
                      {/* {staticDataBase?.staticFooterInfoEmail} */}
                    </Typography>
                  </div>
                </div>
                <div className="info-name space-divider">
                  <a
                    className="mail-link-footer"
                    href={`tel:${staticDataBase?.staticFooterAllIndiaTollFreeNumber?.replace(
                      "-",
                      ""
                    )}`}
                  >
                    <PhoneIcon className="footer-contact-icons" />
                  </a>
                  <Typography
                    component="div"
                    variant="mainsectiontitle"
                    className="footer-titles left-margin-footer"
                  >
                    All India Toll Free:{" "}
                    <Typography
                      component="a"
                      variant="mainsectiontitle"
                      className="footer-links"
                      // href={`tel:${staticDataBase?.staticFooterAllIndiaTollFreeNumber?.replace(
                      //   "-",
                      //   ""
                      // )}`}

                      href={`tel:${footerData?.footerSection?.telephone?.replace(
                        "-",
                        ""
                      )}`}
                    >
                      {footerData?.footerSection?.telephone}
                      {/* {staticDataBase?.staticFooterAllIndiaTollFreeNumber} */}
                    </Typography>
                  </Typography>
                </div>
                <div className="info-name space-divider">
                  <a
                    className="mail-link-footer"
                    // href="https://api.whatsapp.com/send?phone=+919911366666&text=Hello"
                    href={`https://api.whatsapp.com/send?phone=+${footerData?.footerSection?.mobile}&text=Hello`}
                    target="_blank"
                  >
                    <WhatsappIcon className="footer-contact-icons" />
                  </a>
                  <Typography
                    component="div"
                    variant="mainsectiontitle"
                    className="footer-titles left-margin-footer"
                  >
                    Whatsapp Us:{" "}
                    <Typography
                      component="a"
                      variant="mainsectiontitle"
                      className="footer-links"
                      // href="https://api.whatsapp.com/send?phone=+919911366666&text=Hello"
                      href={`https://api.whatsapp.com/send?phone=+${footerData?.footerSection?.mobile}&text=Hello`}
                      target="_blank"
                    >
                      {/* {staticDataBase?.staticFooterWhatsappNumber} */}
                      +{footerData?.footerSection?.mobile}
                    </Typography>
                  </Typography>
                </div>
                <div className="info-social-icons">
                  <Typography
                    component="div"
                    variant="mainsectiontitle"
                    className="footer-titles"
                  >
                    Follow Us
                  </Typography>
                  <div className="footer-icon-group">
                    <a
                      href="https://www.facebook.com/ImagesBazaarIndia/"
                      target="_blank"
                    >
                      <div className="squareIcon">
                        <FaebookIcon className="footer-social-page" />
                      </div>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/imagesbazaar/"
                      target="_blank"
                    >
                      <div className="squareIcon">
                        <LinkedInIcon className="footer-social-page" />
                      </div>
                    </a>
                    <a
                      href="https://twitter.com/imagesbazaarcom"
                      target="_blank"
                    >
                      <div className="squareIcon">
                        <TwitterIcon className="footer-social-page" />
                      </div>
                    </a>
                    {/* <a href="https://in.pinterest.com/" target="_blank">
                      <div className="squareIcon">
                        <PinterestIcon className="footer-social-page" />
                      </div>
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="footer-menu-group">
                <div>
                  <Typography
                    component="div"
                    variant="mainsectiontitle"
                    className="footer-titles"
                  >
                    Company Info
                  </Typography>
                  <div className="footer-menus">
                    <Typography
                      component="div"
                      variant="sectiondata"
                      className="footer-links"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Home
                    </Typography>
                    <Typography
                      component="div"
                      variant="sectiondata"
                      className="footer-links"
                      onClick={() => {
                        navigate("/aboutus");
                      }}
                    >
                      About Us
                    </Typography>
                    <Typography
                      component="div"
                      variant="sectiondata"
                      className="footer-links"
                      onClick={() => {
                        navigate("/testimonial");
                      }}
                    >
                      Testimonials
                    </Typography>
                    <Typography
                      component="div"
                      variant="sectiondata"
                      className="footer-links"
                      onClick={() => {
                        navigate("/imageresearch");
                      }}
                    >
                      Image Research
                    </Typography>
                  </div>
                </div>
                <div>
                  <Typography
                    component="div"
                    variant="mainsectiontitle"
                    className="footer-titles"
                  >
                    Learn More
                  </Typography>
                  <div className="footer-menus">
                    <Typography
                      component="div"
                      variant="sectiondata"
                      className="footer-links"
                      onClick={() => {
                        navigate("/pricing");
                      }}
                    >
                      Pricing
                    </Typography>
                    <Typography
                      component="div"
                      variant="sectiondata"
                      className="footer-links"
                      onClick={() => {
                        navigate("/licensing");
                      }}
                    >
                      Licensing
                    </Typography>
                    <Typography
                      component="div"
                      variant="sectiondata"
                      className="footer-links"
                      onClick={() => {
                        navigate("/termsofuse");
                      }}
                    >
                      Terms of Use
                    </Typography>
                    <Typography
                      component="div"
                      variant="sectiondata"
                      className="footer-links"
                      onClick={() => {
                        navigate("/privacypolicy");
                      }}
                    >
                      Privacy Policy
                    </Typography>
                  </div>
                </div>
                <div>
                  {" "}
                  <Typography
                    component="div"
                    variant="mainsectiontitle"
                    className="footer-titles"
                  >
                    Need Help?
                  </Typography>
                  <div className="footer-menus">
                    <Typography
                      component="div"
                      variant="sectiondata"
                      className="footer-links"
                      onClick={() => {
                        navigate("/contactus");
                      }}
                    >
                      Contact Us
                    </Typography>
                    <Typography
                      component="div"
                      variant="sectiondata"
                      className="footer-links"
                      onClick={() => {
                        navigate("/searchtips");
                      }}
                    >
                      Search Tips
                    </Typography>
                    <Typography
                      component="div"
                      variant="sectiondata"
                      className="footer-links"
                      onClick={() => {
                        navigate("/faq");
                      }}
                    >
                      FAQ
                    </Typography>
                    <Typography
                      component="div"
                      variant="sectiondata"
                      className="footer-links"
                      onClick={() => {
                        navigate("/technical");
                      }}
                    >
                      Technical
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-rights">
              <Typography
                component="div"
                variant="mainsectiontitle"
                className="font-white footer-rights-title alignText-center"
              >
                © {`${ApiConfig.LIVE_WEBSITE_URL}`}. A division of Mash Audio
                Visuals Pvt. Ltd. All rights reserved.
              </Typography>
              <div className="footer-policy">
                <div className="footer-policy-section">
                  <Typography
                    component="div"
                    variant="sectiondata"
                    className="footer-links terms-of-use-footer"
                    onClick={() => {
                      navigate("/termsofuse");
                    }}
                  >
                    Terms of Use
                  </Typography>
                  <Typography
                    component="div"
                    variant="sectiondata"
                    className="footer-links privacypolicy-footer"
                    onClick={() => {
                      navigate("/privacypolicy");
                    }}
                  >
                    Privacy Policy
                  </Typography>
                  <Typography
                    component="div"
                    variant="sectiondata"
                    className="footer-links faqs-footer"
                    onClick={() => {
                      navigate("/faq");
                    }}
                  >
                    FAQs
                  </Typography>
                </div>
                <div>
                  <img
                    className="payment-icons-footer"
                    src={PaymentFooter}
                    alt="PaymentFooter"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
