import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingPeoposals: false,
  proposalsData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  singleProposalsData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    loading: false,
  },
};

export const proposalsDataSlice = createSlice({
  name: "proposalData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loadingPeoposals = action.payload;
    },
    setProposalsData: (state, action) => {
      state.proposalsData.items = action.payload;
    },
    setProposalsDataError: (state, action) => {
      state.proposalsData.error = action.payload;
      state.proposalsData.items = [];
    },
    setSingleProposalsData: (state, action) => {
      state.singleProposalsData.items = action.payload;
    },
    setSingleProposalsDataLoading: (state, action) => {
      state.singleProposalsData.loading = action.payload;
    },
    setSingleProposalsDataError: (state, action) => {
      state.singleProposalsData.error = action.payload;
      state.singleProposalsData.items = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setProposalsData,
  setProposalsDataError,
  setSingleProposalsData,
  setSingleProposalsDataLoading,
  setSingleProposalsDataError,
} = proposalsDataSlice.actions;

export default proposalsDataSlice.reducer;

export const proposalsDataSelector = (state) => state.proposalData;
