import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  jobData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  countryData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  starIndustryData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    loading: false,
  },
  stateData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    isStateAvailable: false,
  },
};

export const jobCountryStateSlice = createSlice({
  name: "jobCountryStateData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setJobData: (state, action) => {
      state.jobData.items = action.payload;
    },
    setJobDataError: (state, action) => {
      state.jobData.error = action.payload;
      state.jobData.items = [];
    },
    setCountryData: (state, action) => {
      state.countryData.items = action.payload;
    },
    setCountryDataError: (state, action) => {
      state.countryData.error = action.payload;
      state.countryData.items = [];
    },
    setStarIndustryData: (state, action) => {
      state.starIndustryData.items = action.payload;
    },
    setStarIndustryDataError: (state, action) => {
      state.starIndustryData.error = action.payload;
      state.starIndustryData.items = [];
    },
    setStateData: (state, action) => {
      state.stateData.items = action.payload;
    },
    setStateDataError: (state, action) => {
      state.stateData.error = action.payload;
      state.stateData.items = [];
      state.stateData.isStateAvailable = false;
    },
    setIsStateAvailable: (state, action) => {
      state.stateData.isStateAvailable = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setJobData,
  setJobDataError,
  setCountryData,
  setCountryDataError,
  setStarIndustryData,
  setStarIndustryDataError,
  setStateData,
  setStateDataError,
  setIsStateAvailable,
} = jobCountryStateSlice.actions;

export default jobCountryStateSlice.reducer;

export const jobCountryStateSelector = (state) => state.jobCountryStateData;
