import axios from "axios";
import ApiConfig from "../config/ApiConfig";
import { STATUS_200, STATUS_300 } from "../data/constants";
import {  errorMessage } from "../lib/helper";
import { toast } from "react-toastify";
import contributoraxois from "../AxiosInterceptor";
import { setSoldImagesData, setSoldImagesDataError,setLoading } from "../redux/contributorSoldImages";

export const GetAllSoldImages = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const resp = await contributoraxois.post(`${ApiConfig.contributorSoldImages}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
        
            dispatch(setSoldImagesData(resp?.data?.data));
            dispatch(setLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
          dispatch(
            setSoldImagesDataError({
              isError: true,
              message: errorMessage(e),
            })
          );
        
        toast.error(errorMessage(e), {
          autoClose: 2000,
        });
        dispatch(setLoading(false));
      }
    };
  }
};