// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   loading: false,
//   PaymentData: {
//     error: {
//       isError: false,
//       message: "",
//     },
//     items: [],
//     totalCount: 0,
//   },
// };

// export const paymentDataSlice = createSlice({
//   name: "paymentData",
//   initialState,
//   reducers: {
//     setLoading: (state, action) => {
//       state.loading = action.payload;
//     },
//     setPaymentData: (state, action) => {
//       state.PaymentData.items = action.payload;
//       state.PaymentData.totalCount = action.payload?.length;
//     },
//     setPaymentError: (state, action) => {
//       state.PaymentData.error = action.payload;
//       state.PaymentData.items = [];
//       state.PaymentData.totalCount = 0;
//     },
  
//   },
// });

// // Action creators are generated for each case reducer function
// export const {
//   setLoading,
//   setPaymentData,
//   setPaymentError,

// } = paymentDataSlice.actions;

// export default paymentDataSlice.reducer;

// export const paymentsSelector = (state) => state.PaymentData;


import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  paymentData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
};

export const paymentDataSlice = createSlice({
  name: "paymentData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPaymentData: (state, action) => {
      state.paymentData.items = action.payload;
    },
    setPaymentError: (state, action) => {
      state.paymentData.error = action.payload;
      state.paymentData.items = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setPaymentData, setPaymentError } =
  paymentDataSlice.actions;

export default paymentDataSlice.reducer;

export const paymentDataSelector = (state) => state.paymentData;

