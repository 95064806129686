import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loadingFolder: false,
    FolderData: {
        error: {
            isError: false,
            message: "",
        },
        items: [],
        count: 0,
    },
};

export const ImagesDataSlice = createSlice({
    name: "folderData",
    initialState,
    reducers: {
        setLoadingFolderName: (state, action) => {
            state.loadingFolder = action.payload;
        },
        setFolderName: (state, action) => {
            state.FolderData.items = action.payload;
            state.FolderData.count = action.payload?.length;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setLoadingFolderName,
    setFolderName,

} = ImagesDataSlice.actions;

export default ImagesDataSlice.reducer;

export const FolderDataSelector = (state) => state.FolderData;
