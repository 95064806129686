import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  HomePage: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    totalCount: 0,
  },
  faqPageData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    loading: false
  },
  FooterPage: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    totalCount: 0,
  },
  TestimonialPage: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    totalCount: 0,
  },
  TechinicalHorizontalPage: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    totalCount: 0,
  },
  TechinicalVerticalPage: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    totalCount: 0,
  },
};


export const CMSDataSlice = createSlice({
  name: "cmsData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setHomeData: (state, action) => {
      state.HomePage.items = action.payload;
      state.HomePage.totalCount = action.payload?.length;
    },
    setHomeDataError: (state, action) => {
      state.HomePage.error = action.payload;
      state.HomePage.items = [];
      state.HomePage.totalCount = 0;
    },
    setFooterData: (state, action) => {
      state.FooterPage.items = action.payload;
      state.FooterPage.totalCount = action.payload?.length;
    },
    setFooterDataError: (state, action) => {
      state.FooterPage.error = action.payload;
      state.FooterPage.items = [];
      state.FooterPage.totalCount = 0;
    },
    setFAQSDataLoading: (state, action) => {
      state.faqPageData.loading = action.payload;
    },
    setFAQSDataData: (state, action) => {
      state.faqPageData.items = action.payload;
      state.faqPageData.error = {
        isError: false,
        message: '',
      };
    },
    setFAQSDataError: (state, action) => {
      state.faqPageData.items = [];
      state.faqPageData.error = action.payload;
    },

    setTestimonialDataLoading: (state, action) => {
      state.TestimonialPage.loading = action.payload;
    },
    setTestimonialData: (state, action) => {
      state.TestimonialPage.items = action.payload;
      state.TestimonialPage.error = {
        isError: false,
        message: '',
      };
    },
    setTestimonialDataError: (state, action) => {
      state.TestimonialPage.items = [];
      state.TestimonialPage.error = action.payload;
    },

    setTechincalHorizontalDataLoading: (state, action) => {
      state.TechinicalHorizontalPage.loading = action.payload;
    },
    setTechincalHorizontalData: (state, action) => {
      state.TechinicalHorizontalPage.items = action.payload;
      state.TechinicalHorizontalPage.error = {
        isError: false,
        message: '',
      };
    },
    setTechinicalHorizontalDataError: (state, action) => {
      state.TechinicalHorizontalPage.items = [];
      state.TechinicalHorizontalPage.error = action.payload;
    },

     setTechincalVerticalDataLoading: (state, action) => {
      state.TechinicalVerticalPage.loading = action.payload;
    },
    setTechincalVerticalData: (state, action) => {
      state.TechinicalVerticalPage.items = action.payload;
      state.TechinicalVerticalPage.error = {
        isError: false,
        message: '',
      };
    },
    setTechinicalVerticalDataError: (state, action) => {
      state.TechinicalVerticalPage.items = [];
      state.TechinicalVerticalPage.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setHomeData,
  setHomeDataError,
  setFooterData,
  setFooterDataError,
  setFAQSDataData,
  setFAQSDataLoading,
  setFAQSDataError,
  setTestimonialDataLoading,
  setTestimonialData,
  setTestimonialDataError,
  setTechincalHorizontalDataLoading,
  setTechincalHorizontalData,
  setTechinicalHorizontalDataError,
  setTechincalVerticalDataLoading,
  setTechincalVerticalData,
  setTechinicalVerticalDataError


} = CMSDataSlice.actions;

export default CMSDataSlice.reducer;

export const CMSDataSelector = (state) => state.CMSData;
