export const BrowseData = {
    tabs: [
        {
            label: 'Add folder',
            id: 1,
        },
        {
            label: 'Upload Image ',
            id: 2,
        }
    ],
};