import { CircularProgress } from "@mui/material";
import React from "react";

function CustomLoading({
  loading,
  loadingStyle,
  loadingText,
  loadingFinishText,
  size,
}) {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "0px",
          ...loadingStyle,
        }}
      >
        {loading ? (
          <>
            {" "}
            <CircularProgress size={size || 40} /> {loadingText}
          </>
        ) : (
          loadingFinishText
        )}
      </div>
    </>
  );
}

export default CustomLoading;

CustomLoading.defaultProps = {
  loading: false,
  loadingStyle: {},
  loadingText: "",
  loadingFinishText: "No Data Found",
};
