import React from 'react';
import { Button, IconButton } from '@mui/material';

function CustomIconButtons({ariaLabel, size, color, variant,disabled, ...props }) {
  return (
    <IconButton aria-label={ariaLabel} size={size} color={color} variant={variant} disabled={disabled} {...props}>
      {props.children}
    </IconButton>
  );
}

export default CustomIconButtons;

CustomIconButtons.defaultProps = {
  color: 'buttonNormal',
  variant: 'contained',
  disabled: false,
};
