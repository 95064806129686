import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../Common/Buttons/CustomButton';
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import {
    Box,
    Typography,
} from "@mui/material";
import '../ContributorSoldImages/contributor.css';
import { fileUploadImage } from '../../../services/ImageInformation';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { getImageName, getSelectImageName } from '../../../lib/helper';


const ContributorBrowseImages = ({ folderName }) => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [loading, setLoader] = useState(false);
    const [selectedImageData, setSelectedImageData] = useState([])
    const dispatch = useDispatch();
    const location = useLocation();
    const folder = location.state;
    const contributorData = JSON.parse(localStorage.getItem("contibuter_data"));
    const handleUpload = async () => {
        setLoader(true);
        try {
            if (selectedImageData) {
                const dataa = JSON.parse(localStorage.getItem("contibuter_data"));
                const formData = new FormData();
                if (dataa)
                    formData.append("contributor_id", dataa.contributor_id);
                formData.append("comment", 'Image uploaded');
                formData.append("folder_name", folder ? folder : folderName);
                for (const image of getSelectImageName(selectedImageData, "name")) {
                    formData.append("files", image);
                }
                let data = await fileUploadImage(formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                if (data?.status === 200) {
                    toast.success("Image uploaded successfully!");
                    navigate('/contributor/folderList')
                    setSelectedImageData([]);
                    setLoader(false);
                }
            }
        } catch (e) { console.log(e) }
        setLoader(false)
    };
    const showConfirmedPreviousData = () => {
        if (page !== 1) {
            setPage(page - 1);
            GetSearchResults(page - 1);
        }
    };
    const deleteSelectedFile = (e, i) => {
        e.preventDefault();
        let arr = selectedImageData;
        let filter = arr.filter((item, index) => { return index !== i });
        setSelectedImageData(filter)
    }

    return (
        <>
            <div className="top-reverse-table">
                <Box
                    component="div"
                    className="account-order-table"
                    sx={{
                        backgroundColor: "helpTopicsColors.backgroundColor",
                    }}
                >
                    {selectedImageData.length > 0 ?
                        <>
                            <Grid container spacing={2} my={2} >
                                {getSelectImageName(selectedImageData, "name")?.map((item, index) => {
                                    return (
                                        <Grid item sx={{ position: "relative" }}>
                                            <img
                                                src={URL.createObjectURL(item)}
                                                // src={selectedImageData ? URL.createObjectURL(selectedImageData) : `${formik.values.img}?w=248&fit=crop&auto=format`}
                                                alt=""
                                                // loading="lazy"
                                                style={{
                                                    maxWidth: 200,
                                                    marginTop: 5,
                                                    maxHeight: 130,
                                                    borderRadius: 10,
                                                    marginLeft: 10,
                                                    width: 195,
                                                    height: 130,
                                                }}
                                            />
                                            <button className="deleteImgBtn"
                                                onClick={(e) => deleteSelectedFile(e, index)}
                                            >&times;</button>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                            <Box
                                component="div"
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <CustomButton
                                    variant="contained" component="label"
                                    className="filterButtonStyle"
                                    sx={{
                                        borderRadius: (theme) =>
                                            theme.shape.borderRadiusSecondary(15),
                                        // marginLeft: "10px",
                                        // marginRight: "10px",
                                        margin: '10px'
                                    }}>
                                    Add more...
                                    <input hidden multiple accept="image/*" type="file" onChange={(e) => {
                                        setSelectedImageData([...selectedImageData, ...e.target.files]);
                                    }} />
                                </CustomButton>
                                <Box
                                    component="div"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <CustomButton
                                        className="commonButtonStyles"
                                        sx={{
                                            borderRadius: (theme) =>
                                                theme.shape.borderRadiusSecondary(15),
                                            // marginLeft: "10px",
                                            // marginRight: "10px",
                                            margin: '10px'
                                        }}
                                        onClick={() => setSelectedImageData([])}
                                    >
                                        Cancel
                                    </CustomButton>
                                    {/* {loading == true ?
                                        <div style={{ textAlign: "center", marginTop: "30px" }}>
                                            <Grid sx={{ color: 'grey.500' }} spacing={2} direction="row">
                                                <CircularProgress color="inherit" />
                                            </Grid>
                                        </div> : */}
                                    <CustomButton
                                        className="commonButtonStyles"
                                        loading={loading}
                                        disabled={loading}
                                        sx={{
                                            borderRadius: (theme) =>
                                                theme.shape.borderRadiusSecondary(15),
                                            margin: '10px'
                                        }}
                                        onClick={() => {
                                            handleUpload()
                                        }
                                        }
                                    >
                                        Upload
                                    </CustomButton>
                                    {/* } */}
                                </Box>
                            </Box>
                        </>
                        :
                        <div style={{ textAlign: "center", marginTop: "30px", marginBottom: "30px" }}>
                            <Grid container spacing={2} my={2}>
                                <Grid item xs={12} textAlign={'center'}>
                                    <CustomButton
                                        variant="contained" component="label"
                                        className="commonButtonStyles"
                                    >
                                        <Typography variant="mainpagetitle" component="div" style={{ fontSize: 16, padding: 5 }}>
                                            Browse
                                            <input hidden multiple accept="image/*" type="file" onChange={(e) => {
                                                setSelectedImageData([...selectedImageData, ...e.target.files]);
                                            }} />
                                        </Typography>
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </div>
                    }
                </Box>
            </div>
        </>
    );

}

export default ContributorBrowseImages
