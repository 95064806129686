import React,{useState} from 'react'
import MainLayout from '../layouts/MainLayout'
import ContributorSoldImagesHistory from '../ContributorSoldImagesHistory/ContributorSoldImagesHistory'



const SoldImageHistoryPage = () => {
    const [navParams, setNavParams] = useState({
        navTitle: "Payment History",
        navSubTitle: "Welcome to ImagesBazaar Contributor Panel"
    })
  
    const handleNavParams = (obj)=>{
      setNavParams({
          ...navParams,
          ...obj
      })
    }
  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
        <ContributorSoldImagesHistory handleNavParams={handleNavParams}/>
    </MainLayout>
   
  )
}

export default SoldImageHistoryPage;