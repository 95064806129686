import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  changePassword: {
    error: {
      isError: false,
      message: "",
    },
    isSuccess: false,
    loading: false,
  },
  resetPassword: {
    error: {
      isError: false,
      message: "",
    },
    isSuccess: false,
  },
};

export const passwordSlice = createSlice({
  name: "passwordData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingChangePassword: (state, action) => {
      state.changePassword.loading = action.payload;
    },
    setChangePassword: (state, action) => {
      state.changePassword.isSuccess = action.payload;
    },
    setChangePasswordError: (state, action) => {
      state.changePassword.error = action.payload;
      state.changePassword.isSuccess = false;
    },
    setresetPassword: (state, action) => {
      state.resetPassword.isSuccess = action.payload;
    },
    setresetPasswordError: (state, action) => {
      state.resetPassword.error = action.payload;
      state.resetPassword.isSuccess = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setLoadingChangePassword,
  setChangePasswordError,
  setChangePassword,
  setresetPassword,
  setresetPasswordError,
} = passwordSlice.actions;

export default passwordSlice.reducer;

export const changeResetPasswordSelector = (state) => state.passwordData;
