import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  paymentHistoryData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
};

export const paymentHistorySlice = createSlice({
  name: "paymentHistoryData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPaymentHistoryData: (state, action) => {
      state.paymentHistoryData.items = action.payload;
    },
    setPaymentHistoryDataError: (state, action) => {
      state.paymentHistoryData.error = action.payload;
      state.paymentHistoryData.items = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setPaymentHistoryData, setPaymentHistoryDataError } =
  paymentHistorySlice.actions;

export default paymentHistorySlice.reducer;

export const paymentHistoryDataSelector = (state) => state.paymentHistoryData;
