import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  ImagesData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    totalCount: 0,
  },
};

export const ImagesDataSlice = createSlice({
  name: "imagesData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setImagesData: (state, action) => {
      state.ImagesData.items = action.payload;
      state.ImagesData.totalCount = action.payload?.length;
    },
    setImagesDataError: (state, action) => {
      state.ImagesData.error = action.payload;
      state.ImagesData.items = [];
      state.ImagesData.totalCount = 0;
    },
  
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setImagesData,
  setImagesDataError,

} = ImagesDataSlice.actions;

export default ImagesDataSlice.reducer;

export const ImagesDataSelector = (state) => state.ImagesData;
