import { useTheme } from '@emotion/react'
import React from 'react'

function Divder({spacing, style}) {
    const theme = useTheme()
  return (
    <div style={{
      padding: theme.spacing(spacing || 2),
      ...style
    }}></div>
  )
}

export default Divder

Divder.defaultProps={
  style: {}
}