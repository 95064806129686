import React, { useState } from 'react'
import MainLayout from '../layouts/MainLayout'
import CustomBox from '../../Common/CustomBox/CustomBox'
import CustomTabs from '../../Common/CustomTabs/CustomTabs'
import { BrowseData } from '../../../data/browsImageData'
import ContributorBrowseTabs from '../ContributorBrowseImages/ContributorBrowseTabs'
import { toast } from 'react-toastify';

const browseImagesPage = () => {
    const [folderName, setFolderName] = useState("")
    const [navParams, setNavParams] = useState({
        navTitle: "Upload Images",
        navSubTitle: "Welcome to ImagesBazaar Contributor Panel"
    })

    const handleNavParams = (obj) => {
        setNavParams({
            ...navParams,
            ...obj
        })
    }
    const [tabValue, setTabValue] = useState(1);

    const handleTabs = (data) => {
        setTabValue(data.id);
        handleNavParams({
            navTitle: data.label,
        });
    };
    return (
        <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
            <CustomBox padding={0}>
                <CustomTabs tabList={BrowseData.tabs} tabClick={handleTabs} disabled={folderName == "" ? true : false} />
            </CustomBox>
            <ContributorBrowseTabs handleNavParams={handleNavParams} activeTab={tabValue} setActiveTab={setTabValue} folderName={folderName} setFolderName={setFolderName} />
        </MainLayout>

    )
}

export default browseImagesPage;
