import React, { useState } from 'react'
import MainLayout from '../layouts/MainLayout'
import ContributorSoldImages from '../ContributorSoldImages/ContributorSoldImages'
import ContributorUploadImages from '../ContributerUpload/ContributerUploadImages'

const uploadImagesPage = () => {
    const [navParams, setNavParams] = useState({
        navTitle: "Upload Images",
        navSubTitle: "Welcome to ImagesBazaar Contributor Panel"
    })

    const handleNavParams = (obj) => {
        setNavParams({
            ...navParams,
            ...obj
        })
    }
    return (
        <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
            <ContributorUploadImages handleNavParams={handleNavParams} />
        </MainLayout>

    )
}

export default uploadImagesPage;