import React from 'react';
import { TextField } from '@mui/material';

function CustomInput(props) {
  return (
    <TextField
      {...props}
    >
      {props.children}
    </TextField>
  );
}

export default CustomInput;
