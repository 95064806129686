// import {

export const sideMenu = [
  {
    pathName: '/contributor/dashboard',
    label: 'Dashboard',
    icon: '',
  },
  {
    pathName: '/contributor/payments',
    label: 'Payment History',
    icon: '',
  },
  {
    pathName: '/contributor/images',
    label: 'View Images',
    icon: '',
  },

  {
    pathName: '/contributor/soldImages',
    label: 'Sold Images',
    icon: '',
  },
  {
    pathName: '/contributor/folderList',
    label: 'Upload',
    icon: '',
  },
  {
    pathName: '/contributor/bankDetail',
    label: 'Bank Detail',
    icon: '',
  },
  {
    pathName: '/contributor/changePassword',
    label: 'Change Password',
    icon: '',
  },

];
