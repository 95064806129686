import React from "react";

const CommonCss = () => {
  const dataa = [
    { setting_id: 1, key_name: "font size", key_value: "16", status: "1" },
    { setting_id: 2, key_name: "tex size", key_value: "17", status: "1" },
    { setting_id: 3, key_name: "color", key_value: "#000000", status: "1" },
  ];

  return (
    <>
      <style>
        {/* {`
                .mainTitle{
        ${dataa.map((datas) => {
          for (const property in datas) {
            return `${property}: ${datas[property]};`;
          }
        })}    
        }
        `} */}
      </style>
    </>
  );
};

export default CommonCss;
