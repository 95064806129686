import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ Children: ReactComponent }) => {
  const token = localStorage.getItem("token") || "";
  const user = localStorage.getItem("user") || "";
  return token && user ? (
    <ReactComponent authUser={true} />
  ) : (
    <Navigate to="/" />
  );
};


const PrivateContributorRoute = ({ Children: ReactComponent }) => {
  const token = localStorage.getItem("contibuter_token") || "";
  const user = localStorage.getItem("contibuter_data") || "";
  return token && user ? (
    <ReactComponent authUser={true} />
  ) : (
    <Navigate to="/contributor/signin" />
  );
};

export default PrivateRoute
export {PrivateContributorRoute};
