import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  user: {
    error: {
      isError: false,
      message: "",
    },
    userDetails: null,
  },
  isPrivilegeUser: false,
  triggerUiRerender: false,
};

export const loginSlice = createSlice({
  name: "loginUserData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUserDetails: (state, action) => {
      state.user.userDetails = action.payload;
      state.user.error = {
        isError: false,
        message: "",
      };
    },
    setUserDetailsError: (state, action) => {
      state.user.userDetails = null;
      state.user.error = action.payload;
    },
    setTriggerUiRender: (state, action) => {
      state.triggerUiRerender = action.payload;
    },
    setPrivilegeUserFlag: (state, action) => {
      state.isPrivilegeUser = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setUserDetails,
  setUserDetailsError,
  setTriggerUiRender,
  setPrivilegeUserFlag,
} = loginSlice.actions;

export default loginSlice.reducer;

export const loginItemsSelector = (state) => state.loginUserData;
