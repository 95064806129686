import React, { useEffect, useState } from 'react';
import { DATA_LIMIT } from '../../../data/constants';
import { useDispatch, useSelector } from 'react-redux';
import ApiConfig, { BASE_IMAGE_URL, S3_BASE_URL } from '../../../config/ApiConfig';
import CustomButton from '../../Common/Buttons/CustomButton';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as LeftArrow } from "../../Assets/svgs/left-arrow-icon.svg"
import { ReactComponent as RightArrow } from "../../Assets/svgs/right-arrow-icon.svg";
import { ReactComponent as ModalCross } from "../../Assets/svgs/modal-cross.svg";
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import {
    Box,
    Table,
    TableCell,
    TableHead,
    TableBody,
    TableRow,
    Typography,
} from "@mui/material";
import '../ContributorSoldImages/contributor.css';
import { useMemo } from 'react';
import Pagination from '../../Common/pagination/Pagination';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DeleteConfirmationDialog from "../../Modals/DeleteConfirmationDialog";
import { GetContributorUploadImages, GetFolderNames, RemoveContributorImage } from '../../../services/imageUpload';
import { UploadImagesDataSelector } from '../../../redux/contributorUploadImages';
import { toast } from 'react-toastify';
import { fetchFile } from '../../../lib/helper';
import CustomInput from '../../Common/CustomInput/CustomInput';
import { FolderDataSelector } from '../../../redux/contributorFolderList';


const ContributorUploadImages = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [downloadURL, setDownloadURL] = useState("");
    const { FolderData } = useSelector(FolderDataSelector);
    const limit = DATA_LIMIT;
    const location = useLocation();
    const folder = location.state;
    const { UploadImagesData } = useSelector(UploadImagesDataSelector);
    const [pageLimit, setPageLimit] = useState(10);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [searchPageNumber, setSearchPageNumber] = useState(1);
    const [finalData, setFinalData] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingImages, setIsLoadingImages] = useState(true);
    const [search, setSearch] = useState('');
    const [folderName, setFolderName] = useState(folder);
    const [imageName, setImageName] = useState('');
    const [contributorId, setContributorId] = useState('');
    const dispatch = useDispatch();
    const contributorData = JSON.parse(localStorage.getItem("contibuter_data"));

    useEffect(() => {
        // getAllImages();
        GetSearchResults(page);
    }, [])


    const dashboardHeader = [
        {
            label: "S.No.",
            id: 1,
        },
        {
            label: "Image Name",
            id: 2,
        },
        {
            label: "Status",
            id: 3,
        },
        {
            label: "Folder Name",
            id: 4,
        },
        {
            label: "Comment",
            id: 5,
        },
        {
            label: "Action",
            id: 6,
        },
    ];

    const GetSearchResults = async (page) => {
        setIsLoadingImages(true);
        const data = JSON.parse(localStorage.getItem("contibuter_data"));
        if (data) {
            const payload = { page: page, limit: pageLimit };
            if (search) {
                payload['search'] = search;
            }
            if (folderName) {
                payload['folder_name'] = folderName;
            }
            let response = await dispatch(GetContributorUploadImages(payload));
            try {
                if (response?.status === 200) {
                    if (response?.data?.count > 0) {
                        // setSearchPageNumber(searchPageNumber + 1);
                        // setFinalData([...finalData, ...response?.data?.rows]);
                        setIsLoadingImages(false);
                    } else {
                        setIsLoadingImages(false);
                    }
                } else {
                    setIsLoadingImages(false);
                }
                if (
                    response?.data?.count === finalData.length ||
                    response?.data?.count <= limit
                ) {
                    setHasMore(false);
                } else {

                    let documentHeight = document.body.scrollHeight;
                    let currentScroll = window.scrollY + window.innerHeight;
                    let modifier = 200;
                    if (currentScroll + modifier > documentHeight) {
                        window.scrollTo(0, window.pageYOffset - 200);
                    }
                    setHasMore(true);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const showConfirmedPreviousData = () => {
        if (page !== 1) {
            setPage(page - 1);
            GetSearchResults(page - 1);
        }
    };
    const showConfirmedNextData = () => {
        setPage(page + 1);
        GetSearchResults(page + 1);
    };

    const makeImageUrl = (image, rank, shootid) => {
        return `${BASE_IMAGE_URL}${shootid}/${rank}-${image}.jpg`
    };

    const handleCloseConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };
    const handleDelteConfirmation = async () => {
        try {
            const payload = {
                image_name: imageName,
            };
            await dispatch(RemoveContributorImage(payload));
            setOpenConfirmationDialog(false);
            GetSearchResults(page);
        } catch (e) {
            toast.error(`Something went wrong. Please try again.`, {
                autoClose: 2500,
            });
        }
        setOpenConfirmationDialog(false);
    };
    const currentPageFunction = (param) => {

        GetSearchResults(param?.selected + 1)
    }

    return (
        <>
            <Grid container spacing={2} my={2}>

                <Grid item xs={12} textAlign={'right'}>
                    <CustomButton

                        className="commonButtonStyles"
                        // sx={{
                        //   borderRadius: (theme) => theme.shape.borderRadiusPrimary(3),
                        //   padding: "10px 5px",
                        //   "@media only screen and (min-width: 100px) and (max-width: 150px)":
                        //     {
                        //       padding: "4px",
                        //     },
                        //   fontSize:"5px"
                        // }}
                        // loading={loading}
                        onClick={() => { navigate('/contributor/browseImages') }}
                    // type="submit"
                    >
                        <Typography variant="mainpagetitle" component="div" style={{ fontSize: 16, padding: 5 }}>
                            Upload
                        </Typography>
                    </CustomButton>
                    <Box
                        component="div"
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <CustomButton
                            className="commonButtonStyles"
                            sx={{
                                borderRadius: (theme) =>
                                    theme.shape.borderRadiusSecondary(15),
                                marginLeft: "10px",
                                marginRight: "10px",
                            }}
                            style={{ right: '92%' }}
                        >
                            <Typography
                                variant="sectiondata"
                                className="font-white"
                                component="div"

                            >
                                <Link to="/contributor/folderList" style={{ textDecoration: 'none', color: 'white' }}>
                                    Back
                                </Link>

                            </Typography>
                        </CustomButton>



                    </Box>
                </Grid>

            </Grid>
            {isLoadingImages ?
                <div style={{ textAlign: "center", marginTop: "30px" }}>
                    <Grid sx={{ color: 'grey.500' }} spacing={2} direction="row">
                        <CircularProgress color="inherit" />
                    </Grid>
                </div>
                : UploadImagesData?.items?.rows?.length === 0 ?
                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                        No Data
                    </div>
                    :
                    <div className="top-reverse-table">
                        <Box
                            component="div"
                            className="account-order-table"
                            sx={{
                                backgroundColor: "helpTopicsColors.backgroundColor",
                            }}
                        >

                            <Box
                                component="div"
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                <IconButton
                                    className="ArrowIconButton"
                                    disabled={page === 1 ? true : false}
                                    onClick={() => showConfirmedPreviousData()}
                                >
                                    <LeftArrow
                                        className={`${page === 1 ? "disabled-arrow" : ""
                                            } left-right-arrow`}
                                    />
                                </IconButton>
                                <CustomButton
                                    className="commonButtonStyles"
                                    sx={{
                                        borderRadius: (theme) =>
                                            theme.shape.borderRadiusSecondary(15),
                                        // marginLeft: "10px",
                                        // marginRight: "10px",
                                        margin: '10px'
                                    }}

                                >
                                    <Typography
                                        variant="sectiondata"
                                        className="font-white"
                                        component="div"
                                    >

                                        Total Records:{Math.ceil(UploadImagesData?.items?.count) || 0}

                                    </Typography>
                                </CustomButton>
                                <IconButton
                                    disabled={
                                        page ===
                                            Math.ceil(UploadImagesData?.items?.count / pageLimit)
                                            ? true
                                            : false
                                    }
                                    className="ArrowIconButton"
                                    onClick={() => showConfirmedNextData()}
                                >
                                    <RightArrow
                                        className={`${page ===
                                            Math.ceil(
                                                UploadImagesData?.items?.count / pageLimit
                                            )
                                            ? "disabled-arrow"
                                            : ""
                                            } left-right-arrow`}
                                    />
                                </IconButton>
                            </Box>
                            <Table aria-label="Pending Orders">
                                <TableHead className="background-light-gray">
                                    <TableRow>
                                        {dashboardHeader?.map((data, index) => {
                                            return (
                                                <TableCell
                                                    key={index}
                                                    sx={{
                                                        backgroundColor: "inherit",
                                                        "&:hover": { backgroundColor: "inherit" },
                                                        borderBottom: "none",
                                                        width: "20%"
                                                    }}

                                                >
                                                    <Typography
                                                        component="div"
                                                        variant="mainsectiontitle"
                                                        className="alignText-center boldFont"
                                                    >
                                                        {data?.label == "Royalty" ? `Royalty(${contributorData?.percentage || 0})%` : data?.label}
                                                    </Typography>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {UploadImagesData?.items?.rows?.map((img, index) => {
                                        return <TableRow key={index}
                                            sx={{
                                                backgroundColor: "inherit",
                                            }}
                                        >
                                            <TableCell
                                                sx={{
                                                    backgroundColor: "inherit",
                                                    "&:hover": { backgroundColor: "inherit" },
                                                    padding: 2,

                                                }}
                                            >
                                                <Typography
                                                    component="div"
                                                    variant="sectiondata"
                                                    className="alignText-center boldFont"
                                                >
                                                    {index + 1}</Typography>

                                                {/* / <img className='img-fluid' style={{ width: '150px' }} src={makeImageUrl(img?.image_name, img?.rank, img?.shootid)} /> */}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    backgroundColor: "inherit",
                                                    "&:hover": { backgroundColor: "inherit" },
                                                    padding: 0,
                                                }}
                                            >
                                                <Typography
                                                    component="div"
                                                    variant="sectiondata"
                                                    className="alignText-center boldFont"
                                                >
                                                    <Link className='textDecoration-none link-text' to={img?.folder_name != null ? `${S3_BASE_URL}contributor/${img?.contributor_id}/${img?.folder_name}/${img?.image_name}` : `${S3_BASE_URL}contributor/${img?.contributor_id}/${img?.image_name}`} target={'_blank'}>
                                                        <img style={{ maxWidth: 200, maxHeight: 200 }} src={img?.folder_name != null ? `${S3_BASE_URL}contributor/${img?.contributor_id}/${img?.folder_name}/${img?.image_name}` : `${S3_BASE_URL}contributor/${img?.contributor_id}/${img?.image_name}`} />
                                                    </Link>
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    backgroundColor: "inherit",
                                                    "&:hover": { backgroundColor: "inherit" },
                                                    padding: 0,
                                                }}
                                            >
                                                <Typography
                                                    component="div"
                                                    variant="sectiondata"
                                                    className="alignText-center boldFont"
                                                    sx={{
                                                        ...(img?.status == 1 ? { color: 'success.main' } : img?.status == 2 ? { color: 'danger.main' } : img?.status == 0 ? { color: 'info.main' } : {}),
                                                        whiteSpace: "nowrap"
                                                    }}>
                                                    {img?.status === 0 ? "Pending" : img?.status === 1 ? "Approved" : "Rejected"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    backgroundColor: "inherit",
                                                    "&:hover": { backgroundColor: "inherit" },
                                                    padding: 0,
                                                }}
                                            >
                                                <Typography
                                                    component="div"
                                                    variant="sectiondata"
                                                    className="alignText-center boldFont"
                                                >
                                                    {img?.folder_name !== null ? img?.folder_name : "N/A"}
                                                </Typography>
                                            </TableCell>

                                            <TableCell
                                                sx={{
                                                    backgroundColor: "inherit",
                                                    "&:hover": { backgroundColor: "inherit" },
                                                    padding: 0,
                                                }}
                                            >
                                                <Typography
                                                    component="div"
                                                    variant="sectiondata"
                                                    className="alignText-center boldFont"
                                                >
                                                    {img?.comment ? img?.comment : "N/A"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    backgroundColor: "inherit",
                                                    "&:hover": { backgroundColor: "inherit" },
                                                    padding: 0,
                                                    width: "40%",
                                                }}
                                            >
                                                {/* <div style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center"
                                                }}>

                                                    <Typography
                                                        component="div"
                                                        variant="sectiondata"
                                                        className="alignText-center boldFont"
                                                    >
                                                        <CustomButton
                                                            className="commonButtonStyles"
                                                            sx={{
                                                                borderRadius: (theme) =>
                                                                    theme.shape.borderRadiusSecondary(15),
                                                                marginLeft: "10px",
                                                                marginRight: "10px",
                                                            }}
                                                            onClick={() => {
                                                                // download(img)
                                                                handleDownloadImage(img)
                                                            }}

                                                        >
                                                            <Typography
                                                                variant="sectiondata"
                                                                className="font-white"
                                                                component="div"
                                                            >
                                                                Download
                                                            </Typography>
                                                        </CustomButton>
                                                    </Typography> */}
                                                {img?.status == "0" ?
                                                    <Typography
                                                        component="div"
                                                        variant="sectiondata"
                                                        className="alignText-center boldFont"
                                                    >
                                                        <CustomButton
                                                            className="commonButtonStyles"
                                                            sx={{
                                                                borderRadius: (theme) =>
                                                                    theme.shape.borderRadiusSecondary(15),
                                                                marginLeft: "10px",
                                                                marginRight: "10px",
                                                            }}
                                                            onClick={() => { setImageName(img?.image_name); setContributorId(img?.contributor_id); setOpenConfirmationDialog(true) }}

                                                        >
                                                            <Typography
                                                                variant="sectiondata"
                                                                className="font-white"
                                                                component="div"
                                                            >
                                                                Delete
                                                            </Typography>
                                                        </CustomButton>
                                                    </Typography>
                                                    : null}
                                                {/* </div> */}
                                            </TableCell>
                                            {openConfirmationDialog === true && (
                                                <DeleteConfirmationDialog
                                                    open={openConfirmationDialog}
                                                    onClose={handleCloseConfirmationDialog}
                                                    imageLink={img?.folder_name != "" ? `${S3_BASE_URL}contributor/${contributorId}/${img?.folder_name}/${imageName}` : `${S3_BASE_URL}contributor/${contributorId}/${imageName}`}
                                                    onConfirmation={handleDelteConfirmation}
                                                />
                                            )}
                                        </TableRow>
                                    })

                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </div>
            }

            {/* <Pagination itemsPerPage={limit} items={UploadImagesData?.items?.count} currentPageFunction={currentPageFunction} /> */}

        </>
    );

}

export default ContributorUploadImages
