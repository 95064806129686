import React, { useState } from 'react'
import ContributorBrowseImages from './ContributorBrowseImages'
import ContributorBrowseFolder from './ContributorBrowseFolder'



function ContributorBrowseTabs({ activeTab, setActiveTab, folderName, setFolderName }) {
    return (
        <>
            {activeTab == 1 && <ContributorBrowseFolder folderName={folderName} setFolderName={setFolderName} />}
            {activeTab == 2 && <ContributorBrowseImages folderName={folderName} />}
        </>
    )
}

export default ContributorBrowseTabs