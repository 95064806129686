import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingCheckSubscribed: false,
  loadingDownloadHistory: false,
  loadingShareLink: false,
  subscribedPlanData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  downloadHistoryData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  nplanImagesDownloadData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    loading: false,
  },
};

export const imageSubscribedDataSlice = createSlice({
  name: "imageSubscribedData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loadingCheckSubscribed = action.payload;
    },
    setDownloadHistoryLoading: (state, action) => {
      state.loadingDownloadHistory = action.payload;
    },
    setLoadingShareLink: (state, action) => {
      state.loadingShareLink = action.payload;
    },
    setSubscribedPlanData: (state, action) => {
      state.subscribedPlanData.items = action.payload;
    },
    setSubscribedPlanDataError: (state, action) => {
      state.subscribedPlanData.error = action.payload;
      state.subscribedPlanData.items = [];
    },
    setDownloadHistoryData: (state, action) => {
      state.downloadHistoryData.items = action.payload;
    },
    setDownloadHistoryDataError: (state, action) => {
      state.downloadHistoryData.error = action.payload;
      state.downloadHistoryData.items = [];
    },
    setNplanDownloadHistoryData: (state, action) => {
      state.nplanImagesDownloadData.items = action.payload;
    },
    setNplannplanImagesDownloadDataError: (state, action) => {
      state.nplanImagesDownloadData.error = action.payload;
      state.nplanImagesDownloadData.items = [];
    },
    setNplannplanImagesDownloadDataLoading: (state, action) => {
      state.nplanImagesDownloadData.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setDownloadHistoryLoading,
  setSubscribedPlanData,
  setSubscribedPlanDataError,
  setDownloadHistoryData,
  setDownloadHistoryDataError,
  setNplanDownloadHistoryData,
  setLoadingShareLink,
  setNplannplanImagesDownloadDataError,
  setNplannplanImagesDownloadDataLoading,
} = imageSubscribedDataSlice.actions;

export default imageSubscribedDataSlice.reducer;

export const imageSubscriptionSelector = (state) => state.imageSubscribedData;
