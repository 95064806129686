import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  SoldImagesData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    totalCount: 0,
  },
};

export const ImagesDataSlice = createSlice({
  name: "soldImagesData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSoldImagesData: (state, action) => {
      state.SoldImagesData.items = action.payload;
      state.SoldImagesData.totalCount = action.payload?.length;
    },
    setSoldImagesDataError: (state, action) => {
      state.SoldImagesData.error = action.payload;
      state.SoldImagesData.items = [];
      state.SoldImagesData.totalCount = 0;
    },
  
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setSoldImagesData,
  setSoldImagesDataError,

} = ImagesDataSlice.actions;

export default ImagesDataSlice.reducer;

export const SoldImagesDataSelector = (state) => state.SoldImagesData;
