import React, { useState } from "react";
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Box, Typography, useTheme } from '@mui/material';
import { ReactComponent as IBMainLogo } from "../../Assets/svgs/ib-main-logo.svg";
// import { IBMainLogo } from '../Assets/svgs/ib-main-logo.svg';
import { sideMenu } from '../../../data/SideMenu';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Logout } from '../../../services/contributorServices';
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
const drawerWidth = 210;
const logoWidth = '100%';
const logoHeight = '30px';
const drawerHeight = '95%';

function ListItemLink({ disabled = false, Icon, primary, to }) {
  const location = useLocation();
  const navigate = useNavigate();

  const renderLink = React.useMemo(() => {
    return React.forwardRef((itemProps, ref) => (
      <Link to={to} ref={ref} {...itemProps} style={{ background: '#0000' }} />
    ));
  }, [to]);

  return (
    <ListItem button disabled={disabled ?? false} component={renderLink} sx={{ paddingTop: "3px", paddingBottom: "3px" }}>
      <ListItemButton selected={to === location.pathname} className={'sidebar-item'}>
        {/* {Icon ? (
          <ListItemIcon sx={{ marginRight: '10px', minWidth: '24px' }}>
            <Icon fill={to === location.pathname ? '#fff' : '#000'} />
          </ListItemIcon>
        ) : null} */}
        <ListItemText primary={primary} classes={{ primary: `fontWeight600 ${to === location.pathname ? "colorWhite" : "colorBlack"}` }} />
      </ListItemButton>
    </ListItem>
  );
}

ListItemLink.defaultProps = {
  disabled: false,
};

const Sidebar = ({ showSmallNav, setShowSmallNav }) => {
  const theme = useTheme();

  // const { logout } = useAuth();
  const handleLogout = async () => {
    let resp = await Logout();
    if (resp?.status === 200) {
      // localStorage.removeItem("contibuter_data");
      // localStorage.removeItem("contibuter_token");
      // navigate("/contributor/signin");
      localStorage.clear();
      window.location.href = "/contributor/signin";
    }
  };

  return (
    <>
      {showSmallNav ?
        <button className="sidebar_toggle" id="toggleBtn" onClick={() => setShowSmallNav(false)}> <BiChevronsLeft /> </button>
        :
        <button className="sidebar_toggle_close" id="toggleBtn" onClick={() => setShowSmallNav(true)}> <BiChevronsRight /> </button>
      }
      {showSmallNav &&
        <Drawer
          sx={{
            width: drawerWidth,
            height: drawerHeight,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              height: drawerHeight,
              boxSizing: 'border-box',
              margin: 1.5,
              backgroundColor: theme.backgroundColor.box.main,
              border: 'none',
              borderRadius: 9,
              'z-index': 5
            },
          }}
          variant="permanent"
          anchor="left"

        >
          <List>
            {/* <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link to="/">
              <IBMainLogo className="ib-main-logo" />
            </Link>
          </Box> */}
            <ListItem key={'AppLogo'}>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: 160,
                  maxHeight: 37,
                  width: '100%'

                }}
              >
                <Link to="/contributor/dashboard">
                  <IBMainLogo className="ib-main-logo" />
                </Link>
              </Box>
              {/* <Box
              sx={{
                width: logoWidth,
                height: logoHeight,
                padding: `0 ${theme.spacing(1)}`,
                marginBottom: theme.spacing(2),
                marginTop: theme.spacing(1.5),
              }}
            >
              <IBMainLogo style={{
                maxWidth: 180,
                maxHeight: 37,
                width: '100%'
              }} />
            </Box> */}
            </ListItem>
            {sideMenu?.map((item, index) => {
              return (
                <ListItemLink
                  Icon={item.icon}
                  primary={item.label}
                  to={item.pathName}
                  key={item.label}
                />
              );
              {
                /* <ListItem key={text}>
              <ListItemButton>
                <ListItemIcon sx={{marginRight: "10px", minWidth: '24px'}}>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem> */

              }
            })}
            <ListItem key={'Logout'}>
              <ListItemButton onClick={() => {
                handleLogout()
              }} className={'sidebar-item'}>
                {/* <ListItemIcon sx={{ marginRight: '10px', minWidth: '24px' }}>
                  <Lock style={{color: "#000"}} />
                </ListItemIcon> */}
                <ListItemText primary={'Sign Out'} classes={{ primary: `fontWeight600 colorBlack` }} />
              </ListItemButton>
            </ListItem>
            {/* <Box sx={{ marginTop: '35px', padding: '0 20px 20px 20px', color: 'rgb(169, 169, 169)' }}>
              <Typography variant="16A3" component="div">
                ©Imagesbazaar.com. A division of Mash Audio Visuals Pvt. Ltd. All rights reserved.
              </Typography>
            </Box> */}
          </List>
        </Drawer>
      }
    </>
  );
}
export default Sidebar;