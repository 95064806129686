import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  subscribedImagesListData: {
    error: {
      isError: false,
      message: "",
    },
    loading: false,
    items: [],
  },
  privilegeImagesListData: {
    error: {
      isError: false,
      message: "",
    },
    loading: false,
    items: [],
  },
};

export const accountImageListSlice = createSlice({
  name: "accountImageListData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingSubscribedImagesListData: (state, action) => {
      state.subscribedImagesListData.loading = action.payload;
    },
    setSubscribedImagesListData: (state, action) => {
      state.subscribedImagesListData.items = action.payload;
    },
    setSubscribedImagesListDataError: (state, action) => {
      state.subscribedImagesListData.error = action.payload;
      state.subscribedImagesListData.items = [];
    },
    setLoadingPrivilegeImagesData: (state, action) => {
      state.privilegeImagesListData.loading = action.payload;
    },
    setPrivilegeImagesData: (state, action) => {
      state.privilegeImagesListData.items = action.payload;
    },
    setPrivilegeImagesDataError: (state, action) => {
      state.privilegeImagesListData.error = action.payload;
      state.privilegeImagesListData.items = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setLoadingSubscribedImagesListData,
  setSubscribedImagesListData,
  setSubscribedImagesListDataError,
  setLoadingPrivilegeImagesData,
  setPrivilegeImagesData,
  setPrivilegeImagesDataError,
} = accountImageListSlice.actions;

export default accountImageListSlice.reducer;

export const accountImageListSelector = (state) => state.accountImageListData;
