import React from 'react'
import { Box } from '@mui/material'

function CustomBox({padding, sx, ...props}) {

  return (
    <Box sx={(theme)=>{
      return {
        backgroundColor: theme.backgroundColor.box.main, 
        padding: theme.spacing(padding == 0 || padding ? padding : 2),
        borderRadius: 13,
        overflow: 'hidden',
        ...sx
      }
    }} {...props}>
      {props.children}
    </Box>
  )
}

export default CustomBox